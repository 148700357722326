import { connect } from 'react-redux';
import { Screen } from "./screen";
const {
    getAgencyRatings,
    changeAcceptenceStatus,
    getProfile,
    setShowRatingToDrivers,updateProfile, rateAgencyByDriver
} = require(`../../../../../../redux/ecr/actions`);

const mapStateToProps = (state) => {
    let agencyData =
      state.CommonReducer.userData && state.CommonReducer.userData.userAgency;
    let agencyDetails = state.CommonReducer.agencyDetails;
    return {
      anyUpdate: state.AgencyDashboardReducer.anyUpdate,
      agencyRatedData: state.RateDriverReducer.agencyRatedData,
      profileData: state.AgencyProfileReducer.profileData,
      showRatingToDrivers: state.RateDriverReducer.showRatingToDrivers,
      initialValues: {
        ...agencyDetails,
        ...agencyData,
        phoneKey:
          agencyDetails &&
          agencyDetails.phoneNumber &&
          agencyDetails.phoneNumber.code,
        phoneNumber:
          agencyDetails &&
          agencyDetails.phoneNumber &&
          agencyDetails.phoneNumber.phone,
      },
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
      rateAgencyByDriver: (data,success,onError) => dispatch(rateAgencyByDriver(data,success,onError)),
      getAgencyRatings: (data, success, failure) =>
        dispatch(getAgencyRatings(data, success, failure)),
      changeStatus: (data, success, failure) =>
        dispatch(changeAcceptenceStatus(data, success, failure)),
      updateProfile: (data, success, failure) => {
        dispatch(updateProfile(data, success, failure));
      },
      getProfile: (success, failure) => dispatch(getProfile(success, failure)),
      setShowRatingToDrivers: (data) => dispatch(setShowRatingToDrivers(data)),
    };
}
export const AgencyRating = connect(mapStateToProps, mapDispatchToProps)(Screen);