import React from 'react';
import "./style.scss";

export const TermsComponent = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 privacy_terms">
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              Terms & Conditions
            </p>
            <hr />
            <p>
              EasyCarRelo and its subsidiaries (collectively, “EasyCarRelo”,
              “we”, “our”, or “us”), provide an online vehicle relocation
              platform that connects rental vehicle agencies with drivers
              seeking to book those vehicles. EasyCarRelo is accessible online
              including www.easycarrelo.co.nz and as an app for mobile devices
              (IOS & Android). EasyCarRelo’s websites, mobile apps and
              associated services are collectively referred to as the “Site”. By
              accessing or using the Site, including by communicating with us
              and rental vehicle agencies, you agree to comply with, and be
              legally bound by, the provisions of these Terms and Conditions
              (these “Terms”), whether or not you become a registered user of
              the Site. These Terms govern your access to and use of the
              Services and constitute a binding legal agreement between you and
              EasyCarRelo.
            </p>
            <p>
              You agree that by accessing the Site, you have read, understood,
              and agree to be bound by all of these Terms and Conditions. If you
              do not agree with all of these Terms and Conditions, then you are
              expressly prohibited from using the Site and you must discontinue
              use immediately.
            </p>
            <p>
              Supplemental Terms and Conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms and Conditions at
              any time and for any reason.
            </p>
            <p>
              We will alert you about any changes by updating the “Last updated”
              date of these Terms and Conditions, and you waive any right to
              receive specific notice of each such change.
            </p>
            <p>
              It is your responsibility to periodically review these Terms and
              Conditions to stay informed of updates. You will be subject to,
              and will be deemed to have been made aware of and to have
              accepted, the changes in any revised Terms and Conditions by your
              continued use of the Site after the date such revised Terms and
              Conditions are posted.{" "}
            </p>

            <p>
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country.
            </p>
            <p>
              Accordingly, those persons who choose to access the Site from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable. 
            </p>
            <p>
              The Site is intended for users who are at least 18 years old.
              Persons under the age of 18 are not permitted to register for the
              Site.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>RISKS</p>
            <p>
              We make no warranties and accept no liability for the information
              provided on the site to drivers or rental vehicle agencies. This
              includes: The actual vehicle availability and accuracy of the
              details provided by the vehicle rental agency(s) about each
              vehicle listings, including but not limited to vehicle type, size
              and model, number of seats, fuel type, insurance, damage
              liabilities and ferry fees.
            </p>
            <p>
              We take no reponsabilities for booking cancellations by the rental
              vehicle agency after booking confirmation for any reason; We take
              no reponsabilities for booking cancellations by the driver after
              booking confirmation, including no-show or for any other reason.
              We take no responsibility for any damage or loss to the rental
              vehicle agencies resulting from any incidents such as intentional
              damages, accidents, cost or fines incurred by the driver. All
              dispute related to such issues must be resolved directly between
              the driver and the rental vehicle agency.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>DISCLAIMER</p>
            <p>
              The site is provided on an as-is and as-available basis. You agree
              that your use of the site and our services will be at your sole
              risk. to the fullest extent permitted by law, we disclaim all
              warranties, express or implied, in connection with the site and
              your use thereof, including, without limitation, the implied
              warranties of merchantability, fitness for a particular purpose,
              and non-infringement. We make no warranties or representations
              about the accuracy or completeness of the site’s content or the
              content of any websites linked to the site and we will assume no
              liability or responsibility for any </p>
              <p>(1) Errors, mistakes, or inaccuracies of content and materials, </p>
              <p>(2) Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the site, </p>
             <p> (3) Any unauthorized access to or use of our secure servers and/or any and all personal information
              and/or financial information stored therein, </p>
             <p>(4) Any interruption
              or cessation of transmission to or from the site, </p>
             <p> (5) Any bugs,
              viruses, trojan horses, or the like which may be transmitted to or
              through the site by any third party, and/or </p>
              <p>(6) Any errors or
              omissions in any content and materials or for any loss or damage
              of any kind incurred as a result of the use of any content posted,
              transmitted, or otherwise made available via the site. we do not
              warrant, endorse, guarantee, or assume responsibility for any
              product or service advertised or offered by a third party through
              the site, any hyperlinked website, or any website or mobile
              application featured in any banner or other advertising, and we
              will not be a party to or in any way be responsible for monitoring
              any transaction between you and any third-party providers of
              products or services.
            </p>
            <p>
              As with the purchase of a product or service through any medium or
              in any environment, you should use your best judgment and exercise
              caution where appropriate.
            </p>

            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              LIMITATIONS OF LIABILITY
            </p>
            <p>
              In no event will we or our directors, employees, or agents be
              liable to you or any third party for any direct, indirect,
              consequential, exemplary, incidental, special, or punitive
              damages, including lost profit, lost revenue, loss of data, or
              other damages arising from your use of the site, even if we have
              been advised of the possibility of such damages.
            </p>
            <p>
              Notwithstanding anything to the contrary contained herein, our
              liability to you for any cause whatsoever and regardless of the
              form of the action, will at all times be limited to the lesser of
              the amount paid, if any, by you to us during the 1 month period
              prior to any cause of action arising or nzd100.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              INDEMNIFICATION
            </p>
            <p>
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or
              arising out of: (1) use of the Site; (2) breach of these Terms and
              Conditions; (3) any breach of your representations and warranties
              set forth in these Terms and Conditions; (4) your violation of the
              rights of a third party, including but not limited to intellectual
              property rights; or (5) any overt harmful act toward any other
              user of the Site with whom you connected via the Site.
            </p>
            <p>
              Notwithstanding the foregoing, we reserve the right, at your
              expense, to assume the exclusive defense and control of any matter
              for which you are required to indemnify us, and you agree to
              cooperate, at your expense, with our defense of such claims. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding which is subject to this indemnification
              upon becoming aware of it. 
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              CANCELLATIONS
            </p>
            <p>
              After a relocation request has been made by the Driver and
              confirmed by the rental vehicle agency, a cancellation fee will
              apply if the Driver decides to cancel the booking or fail to
              collect the vehicle (no show). When the driver cancels the booking
              prior to 24hrs from the pickup date and time, a fee of NZD30 will
              apply. For bookings cancelled on the day of pickup (24hrs) or if
              the driver fails to collect the vehicle (no show), a fee of NZD150
              will apply. These charges will be applied to the bank card the
              Driver has provided to us.
            </p>
            <p>
              Cancellations must be made on the Driver’s portal via the site.
              The driver also must notify the vehicle rental agency via email or
              phone. When a confirmed booking is cancelled by the driver via the
              site, we will return the token(s) used on the specific booking to
              the rental vehicle agency account.{" "}
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              INTELLECTUAL PROPERTY RIGHTS
            </p>
            <p>
              Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, images and graphics on the Site
              (collectively, the “Content”) and the trademarks, service marks,
              and logos contained therein (the “Marks”) are owned or controlled
              by us or licensed to us, and are protected by copyright and
              trademark laws.
            </p>
            <p>
              The Content and the Marks are provided on the Site “AS IS” for
              your information and personal use only. Except as expressly
              provided in these Terms and Conditions, no part of the Site and no
              Content or Marks may be copied, reproduced, aggregated,
              republished, uploaded, posted, publicly displayed, encoded,
              translated, transmitted, distributed, sold, licensed, or otherwise
              exploited for any commercial purpose whatsoever, without our
              express prior written permission.
            </p>

            <p>
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              USER REPRESENTATIONS
            </p>
            <p>By using the Site, you represent and warrant that: </p>
            <p>
              {" "}
              (1) all registration information you submit will be true,
              accurate, current, and complete;{" "}
            </p>
            <p>
              {" "}
              (2) you will maintain the accuracy of such information and
              promptly update such registration information as necessary;
            </p>
            <p>
              {" "}
              (3) you have the legal capacity and you agree to comply with these
              Terms and Conditions;{" "}
            </p>
            <p>(4) you are not under the age of 18 </p>
            <p>
              {" "}
              (5) not a minor in the jurisdiction in which you reside, or if a
              minor, you have received parental permission to use the Site;
            </p>
            <p>
              (6) you will not access the Site through automated or non-human
              means, whether through a bot, script, or otherwise;{" "}
            </p>
            <p>
              (7) you will not use the Site for any illegal or unauthorized
              purpose;{" "}
            </p>
            <p>
              (8) your use of the Site will not violate any applicable law or
              regulation
            </p>
            <p>
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              USER REGISTRATION
            </p>
            <p>
              You may be required to register with the Site. We reserve the
              rights to vet any new and current users (drivers and rental
              vehicle agencies) of our site at any time and delete accounts
              without limitations. You agree to keep your password confidential
              and will be responsible for all use of your account and password.
              We reserve the right to remove, reclaim, or change your account in
              our sole discretion.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              PROHIBITED ACTIVITIES
            </p>
            <p>
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us.
            </p>
            <p>As a user of the Site, you agree not to:</p>
            <ul>
              <li>
                systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.
              </li>
              <li>
                make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses.
              </li>
              <li>
                circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce
                limitations on the use of the Site and/or the Content contained
                therein.
              </li>
              <li>engage in unauthorized framing of or linking to the Site</li>
              <li>
                trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords;
              </li>
              <li>
                make improper use of our support services or submit false
                reports of abuse or misconduct.
              </li>
              <li>
                engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </li>
              <li>
                interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </li>
              <li>
                attempt to impersonate another user or person or use the
                username of another user.
              </li>
              <li>sell or otherwise transfer your profile.</li>
              <li>
                use any information obtained from the Site in order to harass,
                abuse, or harm another person.
              </li>
              <li>
                use the Site as part of any effort to compete with us or
                otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </li>
              <li>
                decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site.
              </li>
              <li>
                attempt to bypass any measures of the Site designed to prevent
                or restrict access to the Site, or any portion of the Site.
              </li>
              <li>
                harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Site to you.
              </li>
              <li>
                delete the copyright or other proprietary rights notice from any
                Content.
              </li>
              <li>
                copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li>
                upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of the Site.
              </li>
              <li>
                upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).
              </li>
              <li>
                except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.
              </li>
              <li>
                disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Site.
              </li>
              <li>
                use the Site in a manner inconsistent with any applicable laws
                or regulations.
              </li>
            </ul>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              GUIDELINES FOR REVIEWS
            </p>
            <p>
              We may provide you areas on the Site to leave reviews or ratings.
              When posting a review, you must comply with the following
              criteria:{" "}
            </p>
            <p>
              (1) you should have firsthand experience with the person/agency
              being reviewed;{" "}
            </p>
            <p>
              (2) your reviews should not contain offensive profanity, or
              abusive, racist, offensive, or hate language;
            </p>
            <p>
              (3) your reviews should not contain discriminatory references
              based on religion, race, gender, national origin, age, marital
              status, sexual orientation, or disability;{" "}
            </p>
            <p>
              (4) your reviews should not contain references to illegal
              activity;
            </p>
            <p>
              (5) you should not be affiliated with competitors if posting
              negative reviews;{" "}
            </p>
            <p>
              (6) you should not make any conclusions as to the legality of
              conduct;{" "}
            </p>
            <p>(7) you may not post any false or misleading statements; </p>
            <p>
              (8) you may not organize a campaign encouraging others to post
              reviews, whether positive or negative.{" "}
            </p>
            <p>
              We may accept, reject, or remove reviews in our sole discretion.
              We have absolutely no obligation to screen reviews or to delete
              reviews, even if anyone considers reviews objectionable or
              inaccurate. Reviews are not endorsed by us, and do not necessarily
              represent our opinions or the views of any of our affiliates or
              partners
            </p>
            <p>
              We do not assume liability for any review or for any claims,
              liabilities, or losses resulting from any review. By posting a
              review, you hereby grant to us a perpetual, non-exclusive,
              worldwide, royalty-free, fully-paid, assignable, and sublicensable
              right and license to reproduce, modify, translate, transmit by any
              means, display, perform, and/or distribute all content relating to
              reviews.
            </p>

            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              MOBILE APPLICATION LICENSE
            </p>
            <p>Use License</p>
            <p>
              If you access the Site via a mobile application, then we grant you
              a revocable, non-exclusive, non-transferable, limited right to
              install and use the mobile application on wireless electronic
              devices owned or controlled by you, and to access and use the
              mobile application on such devices strictly in accordance with the
              Terms and Conditions of this mobile application license contained
              in these Terms and Conditions.
            </p>
            <p>You shall not: </p>
            <p>
              (1) decompile, reverse engineer, disassemble, attempt to derive
              the source code of, or decrypt the application;{" "}
            </p>
            <p>
              (2) make any modification, adaptation, improvement, enhancement,
              translation, or derivative work from the application;
            </p>
            <p>
              (3) violate any applicable laws, rules, or regulations in
              connection with your access or use of the application;{" "}
            </p>
            <p>
              (4) remove, alter, or obscure any proprietary notice (including
              any notice of copyright or trademark) posted by us or the
              licensors of the application;
            </p>
            <p>
              (5) use the application for any revenue generating endeavor,
              commercial enterprise, or other purpose for which it is not
              designed or intended;{" "}
            </p>
            <p>
              (6) make the application available over a network or other
              environment permitting access or use by multiple devices or users
              at the same time;{" "}
            </p>
            <p>
              (7) use the application for creating a product, service, or
              software that is, directly or indirectly, competitive with or in
              any way a substitute for the application;
            </p>
            <p>
              (8) use the application to send automated queries to any website
              or to send any unsolicited commercial e-mail;{" "}
            </p>
            <p>
              (9) use any proprietary information or any of our interfaces or
              our other intellectual property in the design, development,
              manufacture, licensing, or distribution of any applications,
              accessories, or devices for use with the application.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>SOCIAL MEDIA</p>
            <p>
              As part of the functionality of the Site, you may link your
              account with online accounts you have with third-party service
              providers (each such account, a “Third-Party Account”) by either:
              (1) providing your Third-Party Account login information through
              the Site; or (2) allowing us to access your Third-Party Account,
              as is permitted under the applicable Terms and Conditions that
              govern your use of each Third-Party Account.
            </p>
            <p>
              You represent and warrant that you are entitled to disclose your
              Third-Party Account login information to us and/or grant us access
              to your Third-Party Account, without breach by you of any of the
              Terms and Conditions that govern your use of the applicable
              Third-Party Account, and without obligating us to pay any fees or
              making us subject to any usage limitations imposed by the
              third-party service provider of the Third-Party Account.
            </p>
            <p>
              Depending on the Third-Party Accounts you choose and subject to
              the privacy settings that you have set in such Third-Party
              Accounts, personally identifiable information that you post to
              your Third-Party Accounts may be available on and through your
              account on the Site.
            </p>
            <p>
              Please note that if a Third-Party Account or associated service
              becomes unavailable or our access to such Third-Party Account is
              terminated by the third-party service provider, then Social
              Network Content may no longer be available on and through the
              Site. You will have the ability to disable the connection between
              your account on the Site and your Third-Party Accounts at any
              time.
            </p>
            <p>
              Please note that your relationship with the third-party service
              providers associated with your third-party accounts is governed
              solely by your agreement(s) with such third-party service
              providers.
            </p>
            <p>
              We make no effort to review any Social Network Content for any
              purpose, including but not limited to, for accuracy, legality, or
              non-infringement, and we are not responsible for any Social
              Network Content.
            </p>
            <p>
              You acknowledge and agree that we may access your email address
              book associated with a Third-Party Account and your contacts list
              stored on your mobile device or tablet computer solely for
              purposes of identifying and informing you of those contacts who
              have also registered to use the Site.
            </p>
            <p>
              You can deactivate the connection between the Site and your
              Third-Party Account by contacting us using the contact information
              below or through your account settings (if applicable). We will
              attempt to delete any information stored on our servers that was
              obtained through such Third-Party Account, except the username and
              profile picture that become associated with your account.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>SUBMISSIONS</p>
            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Site ("Submissions") provided by you to us are non-confidential
              and shall become our sole property. We shall own exclusive rights,
              including all intellectual property rights, and shall be entitled
              to the unrestricted use and dissemination of these Submissions for
              any lawful purpose, commercial or otherwise, without
              acknowledgment or compensation to you.
            </p>
            <p>
              You hereby waive all moral rights to any such Submissions, and you
              hereby warrant that any such Submissions are original with you or
              that you have the right to submit such Submissions. You agree
              there shall be no recourse against us for any alleged or actual
              infringement or misappropriation of any proprietary right in your
              Submissions.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              THIRD-PARTY WEBSITES AND CONTENT
            </p>
            <p>
              The Site may contain (or you may be sent via the Site) links to
              other websites ("Third-Party Websites") as well as articles,
              images, text, graphics, pictures, designs, music, sound, video,
              information, applications, software, and other content or items
              belonging to or originating from third parties ("Third-Party
              Content").
            </p>
            <p>
              Such Third-Party Websites and Third-Party Content are not
              investigated, monitored, or checked for accuracy, appropriateness,
              or completeness by us, and we are not responsible for any
              Third-Party Websites accessed through the Site or any Third-Party
              Content posted on, available through, or installed from the Site,
              including the content, accuracy, offensiveness, opinions,
              reliability, privacy practices, or other policies of or contained
              in the Third-Party Websites or the Third-Party Content.
            </p>
            <p>
              Inclusion of, linking to, or permitting the use or installation of
              any Third-Party Websites or any Third-Party Content does not imply
              approval or endorsement thereof by us. If you decide to leave the
              Site and access the Third-Party Websites or to use or install any
              Third-Party Content, you do so at your own risk, and you should be
              aware these Terms and Conditions no longer govern.
            </p>
            <p>
              You should review the applicable terms and policies, including
              privacy and data gathering practices, of any website to which you
              navigate from the Site or relating to any applications you use or
              install from the Site. Any purchases you make through Third-Party
              Websites will be through other websites and from other companies,
              and we take no responsibility whatsoever in relation to such
              purchases which are exclusively between you and the applicable
              third party.
            </p>
            <p>
              You agree and acknowledge that we do not endorse the products or
              services offered on Third-Party Websites and you shall hold us
              harmless from any harm caused by your purchase of such products or
              services. Additionally, you shall hold us harmless from any losses
              sustained by you or harm caused to you relating to or resulting in
              any way from any Third-Party Content or any contact with
              Third-Party Websites.{" "}
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>ADVERTISERS</p>
            <p>
              We allow advertisers to display their advertisements and other
              information in certain areas of the Site, such as sidebar
              advertisements or banner advertisements. If you are an advertiser,
              you shall take full responsibility for any advertisements you
              place on the Site and any services provided on the Site or
              products sold through those advertisements.
            </p>
            <p>
              Further, as an advertiser, you warrant and represent that you
              possess all rights and authority to place advertisements on the
              Site, including, but not limited to, intellectual property rights,
              publicity rights, and contractual rights.
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>
              SITE MANAGEMENT
            </p>
            <p>We reserve the right, but not the obligation, to: </p>
            <p>
              (1) monitor the Site for violations of these Terms and Conditions;{" "}
            </p>
            <p>
              (2) take appropriate legal action against anyone who, in our sole
              discretion, violates the law or these Terms and Conditions,
              including without limitation, reporting such user to law
              enforcement authorities;{" "}
            </p>
            <p>
              (3) in our sole discretion and without limitation, refuse,
              restrict access to, limit the availability of, or disable (to the
              extent technologically feasible) any of your Contributions or any
              portion thereof;{" "}
            </p>
            <p>
              (4) in our sole discretion and without limitation, notice, or
              liability, to remove from the Site or otherwise disable all files
              and content that are excessive in size or are in any way
              burdensome to our systems;{" "}
            </p>
            <p>
              (5) otherwise manage the Site in a manner designed to protect our
              rights and property and to facilitate the proper functioning of
              the Site.
              <p style={{ fontSize: "14px", fontWeight: "bold" }}></p>
              <p>
                We care about data privacy and security. Please review our
                Privacy Policy. By using the Site, you agree to be bound by our
                Privacy Policy, which is incorporated into these Terms and
                Conditions. Click here to view our Privacy Policy.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                COPYRIGHT INFRINGEMENTS
              </p>
              <p>
                We respect the intellectual property rights of others. If you
                believe that any material available on or through the Site
                infringes upon any copyright you own or control, please
                immediately notify us using the contact information provided
                below (a “Notification”). A copy of your Notification will be
                sent to the person who posted or stored the material addressed
                in the Notification.{" "}
              </p>
              <p>
                Please be advised that pursuant to the law you may be held
                liable for damages if you make material misrepresentations in a
                Notification. Thus, if you are not sure that material located on
                or linked to by the Site infringes your copyright, you should
                consider first contacting an attorney.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                TERM AND TERMINATION
              </p>
              <p>
                These Terms and Conditions shall remain in full force and effect
                while you use the Site.Without limiting any other provision of
                there terms and conditions,we reserve the right to,in our sole
                discretion and without notice or liability ,deny access to and
                use of the site (including blocking cretain IP addresse),to any
                person for any reason or for no reason,including without
                limitation for breach of any representation,warranty,or covenant
                contained in these terms and conditions or of any applicable law
                or regulation.We may terminate your use or participation in the
                site or delete your account and any content or information that
                you posted at any time,without warning, in our sole discretion.
              </p>
              <p>
                If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
              </p>
              <p>
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                MODIFICATIONS AND INTERRUPTIONS
              </p>
              <p>
                We reserve the right to change, modify, or remove the contents
                of the Site at any time or for any reason at our sole discretion
                without notice. However, we have no obligation to update any
                information on our Site. We also reserve the right to modify or
                discontinue all or part of the Site without notice at any time.
              </p>
              <p>
                We will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Site.
              </p>
              <p>
                We cannot guarantee the Site will be available at all times. We
                may experience hardware, software, or other problems or need to
                perform maintenance related to the Site, resulting in
                interruptions, delays, or errors.
              </p>
              <p>
                We reserve the right to change, revise, update, suspend,
                discontinue, or otherwise modify the Site at any time or for any
                reason without notice to you. You agree that we have no
                liability whatsoever for any loss, damage, or inconvenience
                caused by your inability to access or use the Site during any
                downtime or discontinuance of the Site.{" "}
              </p>
              <p>
                Nothing in these Terms and Conditions will be construed to
                obligate us to maintain and support the Site or to supply any
                corrections, updates, or releases in connection therewith.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                GOVERNING LAW{" "}
              </p>
              <p>
                These Terms and Conditions and your use of the Site are governed
                by and construed in accordance with the laws of New Zealand
                applicable to agreements made and to be entirely performed
                without regard to its conflict of law principles.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                DISPUTE RESOLUTION
              </p>
              <p>
                Any legal action of whatever nature brought by either you or us
                (collectively, the “Parties” and individually, a “Party”) shall
                be commenced or prosecuted in the courts located in New Zealand,
                and the Parties hereby consent to, and waive all defenses of
                lack of personal jurisdiction and forum non conveniens with
                respect to venue and jurisdiction in such countries, states and
                federal courts
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                CORRECTIONS
              </p>
              <p>
                There may be information on the Site that contains typographical
                errors, inaccuracies, or omissions that may relate to the Site,
                including descriptions, pricing, availability, and various other
                information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the
                information on the Site at any time, without prior notice.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>USER DATA</p>
              <p>
                We will maintain certain data that you transmit to the Site for
                the purpose of managing the Site, as well as data relating to
                your use of the Site. Although we perform regular routine
                backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken
                using the Site.
              </p>
              <p>
                You agree that we shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of
                such data.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </p>
              <p>
                Visiting the Site, sending us emails, and completing online
                forms constitute electronic communications. You consent to
                receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically, via email and on the Site,
                satisfy any legal requirement that such communication be in
                writing.
              </p>
              <p>
                You hearby agree to the use of elctronic
                signatures,contacts,orders,and other records,and to electronic
                delivery of notices ,policies,and records of transactions
                initiated or completed by us or via the site.
              </p>
              <p>
                You hereby waive any rights or requirements under any statutes,
                regulations, rules, ordinances, or other laws in any
                jurisdiction which require an original signature or delivery or
                retention of non-electronic records, or to payments or the
                granting of credits by any means other than electronic means.
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                MISCELLANEOUS
              </p>
              <p>
                These Terms and Conditions and any policies or operating rules
                posted by us on the Site constitute the entire agreement and
                understanding between you and us. Our failure to exercise or
                enforce any right or provision of these Terms and Conditions
                shall not operate as a waiver of such right or provision.
              </p>
              <p>
                These Terms and Conditions operate to the fullest extent
                permissible by law. We may assign any or all of our rights and
                obligations to others at any time. We shall not be responsible
                or liable for any loss, damage, delay, or failure to act caused
                by any cause beyond our reasonable control.
              </p>
              <p>
                If any provision or part of a provision of these Terms and
                Conditions is determined to be unlawful, void, or unenforceable,
                that provision or part of the provision is deemed severable from
                these Terms and Conditions and does not affect the validity and
                enforceability of any remaining provisions.
              </p>
              <p>
                There is no joint venture, partnership, employment or agency
                relationship created between you and us as a result of these
                Terms and Conditions or use of the Site. You agree that these
                Terms and Conditions will not be construed against us by virtue
                of having drafted them.
              </p>
              <p>
                You hereby waive any and all defenses you may have based on the
                electronic form of these Terms and Conditions and the lack of
                signing by the parties hereto to execute these Terms and
                Conditions.
              </p>
            </p>
            <p style={{ fontSize: "14px", fontWeight: "bold" }}>CONTACT US </p>
            <p>
              In order to resolve a complaint regarding the Site or to receive
              further information regarding use of the Site, please contact us
              at:{" "}
            </p>
            <p> EasyCarRelo</p>
            <p>Auckland, New Zealand</p>
            <p>0800 820 824</p>
            <p>info@easycarrelo.co.nz </p>
          </div>
        </div>
      </div>
    );
}