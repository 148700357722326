import React, { useEffect, useState } from "react";
import { reduxForm, Field, reset,change as changeField } from "redux-form";
import { connect } from "react-redux";
import "./style.scss";
import validator from "./validator";
import { SettingsRemoteOutlined } from "@material-ui/icons";
const {
  defaultConfig: { PLATFORM, LOCATION },
} = require(`../../../../../config/default`);
const { Form } = require(`../../../../../components/${PLATFORM}/atoms/form`);
const {
  RadioButtons,
} = require(`../../../../../components/${PLATFORM}/atoms/radio-button`);
const {
  InputSubmit,
} = require(`../../../../../components/${PLATFORM}/atoms/input-submit`);
const { Input } = require(`../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../helpers/${PLATFORM}`);
const {
  STRINGS,
} = require(`../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

const ContactForm = ({
  handleSubmit = () => {},
  initialize,
  credentials = {
    username: "",
    email: "",
    phnNumber: "",
    driverOrAgency: "",
    msg: "",
  },
  setRole = () => {},
  onEmailChange = () => {},
  onSubmit = (e) => {},
  children,
  role,
  setField = () => {},
  type,
  resetForm = () => {},
  verifyTheCapcha = () => {},
  dispatch,
  handleChange = () => {},
}) => {
  useEffect(() => {
    // resetForm()
    dispatch(initialize());
  }, [type]);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  return (
    <>
    <div className="get_in_touch_form">
      <Form
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-md-12 label_float">
            <label className="title">Full name:</label>
            <Field
              name={STRINGS.NAME_INPUT}
              component={Input}
              placeholder={STRINGS.NAME_INPUT}
              config={{
                type: STRINGS.NAME_INPUT,
              }}
            />
            <label className="title">Email address:</label>
            <Field
              name={STRINGS.EMAIL_INPUT_NAME}
              component={Input}
              placeholder={STRINGS.EMAIL_INPUT_NAME}
              config={{
                type: STRINGS.EMAIL_INPUT_NAME,
              }}
            />
            <label className="title">Phone number:</label>
            <Field
              name={STRINGS.PHONE_NUMBER_KEY}
              component={Input}
              placeholder={STRINGS.PHONE_NUMBER_KEY}
              config={{
                type: STRINGS.PHONE_NUMBER_KEY,
              }}
            />
            <div className="row driver_row_field ml-0">
              <label class="title"> Are you a driver or agency? </label>
              <br />
              <div className="form-group">
                <div className="form-row custom_radi">
                  <Field
                    name={STRINGS.DRIVER_OR_AGENCY_KEY}
                    component={RadioButtons}
                    selectedValue={role}
                    radioGroupItems={[{ label: "Driver" }, { label: "Agency" }]}
                    handleValueChange={(value) => {
                      changeField(
                        "getInTouch",
                        STRINGS.DRIVER_OR_AGENCY_KEY,
                        !!!value
                      );
                      setRole(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <label className="title">Message:</label>
            <Field
              name={STRINGS.MESSAGE_FIELD_KEY}
              component={Input}
              placeholder={STRINGS.MESSAGE_FIELD_KEY}
              config={{
                type: STRINGS.MESSAGE_FIELD_KEY,
              }}
            />
          </div>
        </div>
        
        {children}
        <div className="col-md-12">
          <div className="row">
            <InputSubmit
              buttonLabel={"Send"}
              buttonStyle={"button"}
              containerStyle={"mt-4"}
            />
          </div>
        </div>
      </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => {
      dispatch(reset("getInTouch"));
    },
  };
};

const reduxFormFunction = reduxForm({
  form: "getInTouch",
  onSubmitFail,
  validate: (values) => validator(values),
  enableReinitialize: true,
})(ContactForm);

export const ContactReduxForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormFunction);
