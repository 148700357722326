import React from "react";
import "./style.scss";

export const CancellationPolicyComponent = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 privacy_terms">
            <h4>Driver’s Cancellation Policy:</h4>
            <hr />
            <p>
              After a relocation request has been made by the Driver and
              confirmed by the rental vehicle agency, a cancellation fee will
              apply if the Driver decides to cancel the booking or fail to
              collect the vehicle (no show). When the driver cancels the booking
              prior to 24hrs from the pickup date and time, a fee of NZD30 will
              apply. For bookings cancelled on the day of pickup (24hrs) or if
              the driver fails to collect the vehicle (no show), a fee of NZD150
              will apply. These charges will be applied to the bank card the
              Driver has provided to us.
            </p>
            <p>
              Cancellations must be made on the Driver’s portal via the site.
              The driver also must notify the vehicle rental agency via email or
              phone.
            </p>
          </div>
        </div>
      </div>
    );
};