import React, { useState, useEffect } from 'react';
const { defaultConfig: { PLATFORM,LOCATION } } = require(`../../../../../../config/default`);
const {
    LAYOUTS, LIMITS,
    ELEMENT_ID,
    LABELS,
    DRIVER_RATING_STATUS
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`)
const {
  STRINGS,
} = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const {
  StarRatingComponent,
} = require(`../../../../../../components/${PLATFORM}/atoms/star-component`);
const {
  CustomTooltip,
} = require(`../../../../../../components/${PLATFORM}/atoms/custom-tooltip`);
export const Screen = ({ getAgencyRatings, rateAgencyByDriver, anyUpdate,initialValues,updateProfile, agencyRatedData,changeStatus,profileData,getProfile,setShowRatingToDrivers,showRatingToDrivers }) => {
    const [index, setIndex] = useState(0)
    const [sortType, setSortType] = useState(-1)
    const [commentModal, setCommentModal] = useState(false);
    const [sortKey, setSortKey] = useState(ELEMENT_ID.createdAt)
    const [modalVisible, setModalVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });

    useEffect(() => {
        getAgencyRatings({ index, limit: LIMITS.limitPerPage, sortKey: ELEMENT_ID.createdAt, sortType }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        })
    }, [])
  useEffect(() => {
  getProfile(()=>{},()=>{})
},[])
    useEffect(() => {
        getAgencyRatings({ index, limit: LIMITS.limitPerPage, sortKey, sortType }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        })
    }, [anyUpdate])

    const sort = (element) => {
        switch (element) {
            case ELEMENT_ID.starRating:
                if (sortKey === ELEMENT_ID.starRating) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.starRating, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.starRating);
                }
                break;

            case ELEMENT_ID.driverName:
                if (sortKey === ELEMENT_ID.driverName) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.driverName, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.driverName);
                }
                break;
            case ELEMENT_ID.vehicle:
                if (sortKey === ELEMENT_ID.vehicle) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.vehicle, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.vehicle);
                }
                break;
            case ELEMENT_ID.rego:
                if (sortKey === ELEMENT_ID.rego) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.rego, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.rego);
                }
                break;
            case ELEMENT_ID.pickUpLocation:
                if (sortKey === ELEMENT_ID.pickUpLocation) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.pickUpLocation, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.pickUpLocation);
                }
                break;
            case ELEMENT_ID.pickUpDate:
                if (sortKey === ELEMENT_ID.pickUpDate) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.pickUpDate, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.pickUpDate);
                }
                break;
            case ELEMENT_ID.dropOffLocation:
                if (sortKey === ELEMENT_ID.dropOffLocation) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.dropOffLocation, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.dropOffLocation);
                }
                break;
            case ELEMENT_ID.dropOffDate:
                if (sortKey === ELEMENT_ID.dropOffDate) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.dropOffDate, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.dropOffDate);
                }
                break;
            case ELEMENT_ID.lastUpdated:
                if (sortKey === ELEMENT_ID.lastUpdated) {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: sortKey, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                }
                else {
                    getAgencyRatings({ index: index, limit: LIMITS && LIMITS.limitPerPage, sortKey: ELEMENT_ID.lastUpdated, sortType: -1 * sortType })
                    setSortType(-1 * sortType);
                    setSortKey(ELEMENT_ID.lastUpdated);
                }
                break;

            default: break;
        }
    }
     const commentModalVisibility = (data) => {
       setModalVisible(true);
     };
    const onStatusToggle = (data) => {
        changeStatus(data, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg || 'error'
            });
            setOpenSnackbar(true)
        })
    }
    const rating = initialValues.hasOwnProperty('showRatingToDrivers') ? initialValues.showRatingToDrivers : true;
  const [showRating,changeShowRatingToDrivers] = useState(rating)
    const [hovered, checkHover] = useState({ first: false });
    return (
        <div className="app-main_outer">
            <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
            />
        <div className="container-fluid">
          <div className="row" onClick={(e) => e.stopPropagation()}>
            <div className="col-md-12">
              <div className="row">
                <CustomTooltip
                  tooltipText={
                    "In this section you can view your Rating given by the drivers."
                  }
                  hoveredKey="first"
                  checkHover={checkHover}
                  hovered={hovered}
                  style={{ left: "275px", top: "30px" }}
                  imgStyle={{ marginRight: "15px" }}
                  after={<StarRatingComponent
                    readonly={true}
                    initialRating={profileData && profileData.overallRating}
                  />}
                >
              <h5>
                {LABELS.RatingByDrivers} is {"  "}
                <span className="pr-3">
                  {"..."}{" "}
                  {profileData && profileData.overallRating
                    ? profileData.overallRating % 1
                      ? profileData.overallRating.toFixed(2)
                      : profileData.overallRating
                    : 0}{" "}
                      {STRINGS.OUT_OF} 5
                    </span>{" "}
              </h5>
                </CustomTooltip>
              </div>
             
                <span className={`switchButton`}>
                  <label className={``}>
                    <input
                      className={"toggle-button"}
                      type="checkbox"
                                    onChange={() => {
                                        updateProfile(
                                          {
                                            companyName: initialValues.name,
                                            address: initialValues.address,
                                            email: initialValues.email,
                                            suburb: initialValues.suburb,
                                            city: initialValues.city,
                                            showRatingToDrivers: initialValues.showRatingToDrivers ? !initialValues.showRatingToDrivers : !showRating ,
                                            country: initialValues.country,
                                            phoneNumber: {
                                              code: initialValues.phoneKey,
                                              phone: initialValues.phoneNumber,
                                            },
                                            notificationToPickupBranch:
                                              initialValues.notificationToPickupBranch,
                                            postcode: initialValues.postcode,
                                            logo: initialValues.logo,
                                            requestedInformationToDriver:
                                              initialValues.requestedInformationToDriver,
                                            confirmationInformationToDriver:
                                              initialValues.confirmationInformationToDrive,
                                            website: initialValues.website,
                                            onlyCreditCardAllowed:
                                              initialValues.onlyCreditCardAllowed,
                                            paymentConditions:
                                              initialValues.paymentConditions,
                                            shortDescription:
                                              initialValues.shortDescription,
                                            driverLicenseRequirement: {
                                              driverWithRestrictedLicense:initialValues.driverLicenseRequirement.driverWithRestrictedLicense,
                                              overseasDriversAllowed:initialValues.driverLicenseRequirement.overseasDriversAllowed,
                                            },
                                            status: initialValues.status,
                                          },
                                          (response) => {
                                            setSnackBarData({
                                              variant: response.status
                                                ? "success"
                                                : "error",
                                              message: "Show overall Rating to drivers updated sucessfully" || "error",
                                            });
                                            setOpenSnackbar(true);
                                          },
                                          (response) => {
                                            setSnackBarData({
                                              variant: response.status
                                                ? "success"
                                                : "error",
                                              message: response.msg || "error",
                                            });
                                            setOpenSnackbar(true);
                                          }
                                        );
                                        changeShowRatingToDrivers(!showRating)
                                            setShowRatingToDrivers(!showRating)
                                    }
                      }
                      checked={initialValues && initialValues.showRatingToDrivers || showRating}
                    />
                  </label>
                </span>
                <span className="pr-3" style={{marginLeft:'10px'}}>Show Overall Rating to drivers</span>
            </div>
          </div>
          <div className="table-pagination mt-4">
            <CustomTable
              setCommentModal={setCommentModal}
              DRIVER_RATING_STATUS={DRIVER_RATING_STATUS}
              tableHeader={LAYOUTS.agencyRatingTable}
              tableData={agencyRatedData && agencyRatedData.listings}
              onStatusChange={onStatusToggle}
              sort={sort}
              commentModal={commentModal}
              rateAgencyByDriver={rateAgencyByDriver}
              setModalVisible={commentModalVisibility}
              tablePage="driverRating"
              ratedByDriver={true}
              tableType="ratedTable"
            />
          </div>
          {(agencyRatedData && agencyRatedData.totalCount) >(LIMITS && LIMITS.limitPerPage) && 
            <CustomPagination
                        limit={LIMITS && LIMITS.limitPerPage}
                        currentPage={index + 1}
                        totalPages={agencyRatedData && agencyRatedData.totalCount}
                        itemsCount={agencyRatedData && agencyRatedData.listings && agencyRatedData.listings.length}
                        onPageChange={(value) => {
                            if (!window.navigator.onLine) {
                                setSnackBarData({
                                    variant: "error",
                                    message:"You appear to be offline. Please check your connection.",
                                });
                                setOpenSnackbar(true)
                            }
                            else {
                                getAgencyRatings({
                                     limit: LIMITS && LIMITS.limitPerPage,
                      index: value && value.selected,
                      sortType: sortType,
                      sortKey: sortKey
                    },
                    (response) => {
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg || "error",
                      });
                      setOpenSnackbar(true);
                    },
                    (response) => {
                      setSnackBarData({
                        variant: response.status ? "success" : "error",
                        message: response.msg || "error",
                                    });
                                    setOpenSnackbar(true)
                                })
                                setIndex(value && value.selected)
                            }
                        }}
                    />
                }
            </div>
        </div>
    )
}