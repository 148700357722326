import React, { useMemo } from 'react';
import {
    injectStripe
} from 'react-stripe-elements';
import './styles.scss';
import TextField from '@material-ui/core/TextField';
const { defaultConfig: { PLATFORM,LOCATION,VERSION } } = require(`../../../../../../config/default`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`)
const {TermsPopup} = require(`../../../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/terms-popup/index`)
const { ROUTES, PAY_ICON, REMOVE_ICON, VISA_ICON,
    MAESTRO_ICON,
    MASTER_CARD_ICON,
    JCB_ICON,
    HYPER_CARD_ICON,
    ELO_ICON,
    DISCOVER_ICON,
    DINERS_ICON,
    AMEX_ICON,
    ALIPAY_ICON,
    PAYPAL_ICON,
    UNION_ICON,
    VERVE_ICON } = require(`../../../../../../shared/ecr/constants`)
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

// const handleBlur = () => {
//     // console.log('[blur]');
// };
// const handleChange = (event) => {
//     // if (event.error) {
//     //     setError(event.error.message);
//     // } else {
//     //     setError(null);
//     // }
// }

// const handleFocus = () => {
//     // console.log('[focus]');
// };
// const handleReady = () => {
//     // console.log('[ready]');
// };


const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    // fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        []
    );

    return options;
};

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.expMonth = React.createRef();
        this.expYear = React.createRef();
    }
    componentDidMount() {
        const { cards } = this.props

        let tempSelectedCard;
        cards.filter((item) => {
            if (item.default) {
                tempSelectedCard = item.id
            }
        })
        this.setState({
            selectedCard: tempSelectedCard
        })

this.loadStripe();   


    }
    state = {
        selectedCard: 1,
        snackbarData: {
            variant: '',
            message: ""
        },
        readTerms:false,
        cvc: '',
        message: '',
        expYear: '',
        expMonth: '',
        cardNumber: '',
        formProcess: false,
        termsPopupVisible:false,
        discount: '',
        exp_month: 1,
        exp_year:'2026',
        openSnackBar: false,
        confirmBox: false,
        deleteItemData: '',
        name:''
    }
    handleChange = (e) => {
        this.setState({
      [e.target.name] : e.target.validity.valid ? e.target.value : this.state[e.target.name]
    });
    }
    handleReadTerms = () => {
        this.setState({readTerms:!this.state.readTerms})
    }
    loadStripe = () => {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey('pk_live_vT72SYq1YafKURkW1BJE14LS00jziTgrH0');
      }
      window.document.body.appendChild(s);
    }
    }
    handlePopupTerms = () => {
        this.setState({termsPopupVisible:!this.state.termsPopupVisible})
    }
    handleSubmit = (ev) => {
        ev.preventDefault();
        const { startLoader, stopLoader } = this.props
        startLoader()
        if (!this.state.readTerms) {
             this.setState({
                                snackbarData: {
                                    variant: 'error',
                                    message: 'Please Accept Terms and Conditions to proceed'
                 },
             })
            this.setState({ openSnackBar: true })
            stopLoader()
        }
        this.state.readTerms && window.Stripe.card.createToken({
         number: this.state.cardNumber,
         exp_month: this.state.expMonth,
         exp_year: this.state.expYear,
         cvc: this.state.cvc,
         name:this.state.name
    }, (status, response) => {
       stopLoader()
                 if (status === 200 ) {
                     this.setState({
                         message: `Success! Card token ${response.card.id}.`,
                         formProcess: false
                     });
                       startLoader()
                     let promoData = !!this.state.validPromo ? { promoCode: this.state.validPromo } : {};
                        this.props.purchaseToken({
                            cardId: (response  && response.card && response.card.id),
                            packageId: this.props.packageId,
                            stripeToken: (response && response.id),
                          ...promoData,
                        }, (response) => {
                            this.setState({
                                snackbarData: {
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg || 'error'
                                }
                            })
                            this.setState({ openSnackBar: true })
                            setTimeout(() => {
                                this.props.history.push(ROUTES.DASHBOARD)
                            }, 1000)
                                stopLoader()
                                const data = { ...this.state, cvc: '', expMonth: '', expYear: '', cardNumber: '', name: '' }
                                 this.setState(data)
                        },
                            (response) => {
                                this.setState({
                                    snackbarData: {
                                        variant: response.status ? 'success' : 'error',
                                        message: response.msg || 'error'
                                    }
                                })
                                this.setState({ openSnackBar: true })
                                stopLoader()
                            }
                        )
                    }
       else {
                     startLoader()
                      this.setState({
                                snackbarData: {
                                    variant: 'error',
                                    message: response.error.message || 'error'
                                }
                         })
                     this.setState({ openSnackBar: true })
                     stopLoader()
                     if (!this.state.readTerms)
                     {
                         startLoader()
                         this.setState({
                                snackbarData: {
                                    variant: 'error',
                                    message: 'Please Accept Terms and Conditions to proceed'
                                }
                         })
                         this.setState({ openSnackBar: true })
                          stopLoader()
                         }
        this.setState({
          message: response.error.message,
          formProcess: false
        });
          stopLoader()
                 }
                stopLoader()
    });
        
    };
    componentWillReceiveProps(nextProps) {
        const { getCards } = this.props

        if (nextProps.anyUpdate !== this.props.anyUpdate) {
            getCards()
        }
    };
    cardDetector = (card) => {
        switch (card.toLowerCase()) {
            case 'visa':
                return VISA_ICON
            case 'mastercard':
                return MASTER_CARD_ICON
            case 'maestro':
                return MAESTRO_ICON
            case 'jcb':
                return JCB_ICON
            case 'hypercard':
                return HYPER_CARD_ICON
            case 'elo':
                return ELO_ICON
            case 'discover':
                return DISCOVER_ICON
            case 'diners':
                return DINERS_ICON
            case 'amex':
                return AMEX_ICON
            case 'alipay':
                return ALIPAY_ICON
            case 'paypal':
                return PAYPAL_ICON
            case 'unionpay':
                return UNION_ICON
            case 'verve':
                return VERVE_ICON
            default:
                return PAY_ICON
        }
    };

    getCards = () => {

    }

    render() {

        const { cards, deleteCard,applyPromo,tokenPrice} = this.props
        const { selectedCard, openSnackBar, snackbarData, confirmBox,promoText,discount } = this.state
        const price = tokenPrice;
        const discountPrice = (discount * price) / 100;
        return (
            <>
                <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => this.setState({ openSnackBar: false })}
                    variant={snackbarData.variant}
                    message={snackbarData.message}
                />
                <TermsPopup
          dialogContent={STRINGS.TERMS_POPUP_CONTENT}
          dialogTitle={"Terms and Conditions"}
                    modalType={"termsPopup"}
                     cancelButtonTitle={"CANCEL"}
          agreeButtonTitle={"I AGREE"}
          onCancel={() => {
              this.setState({ readTerms: false });
              this.setState({ termsPopupVisible: false });
          }}
          onAgree={() => {
              this.setState({ readTerms: true });
              this.setState({ termsPopupVisible: false });
          }}
          modalVisibility={this.state.termsPopupVisible}
          toggleDialogModal={() => this.handlePopupTerms()}
          onRejection={() => {
            this.handlePopupTerms();
          }}
        />
                {confirmBox && <DecisionPopup
                    modalVisibility={confirmBox}
                    dialogTitle={'Delete Card'}
                    toggleDialogModal={() => this.setState({ confirmBox: false })
                    }
                    dialogContent={'Are you sure, you want to delete this card?'}
                    confirmButtonTitle={'confirm'}
                    rejectButtonTitle={'cancel'}
                    onConfirmation={() => {
                        const { deleteItemData, } = this.state
                        deleteCard(deleteItemData, (response) => {
                            this.setState({
                                snackbarData: {
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg || 'error'
                                }
                            })
                            this.setState({ openSnackBar: true })
                            this.setState({ confirmBox: false })

                        }, (response) => {
                            this.setState({
                                snackbarData: {
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg || 'error'
                                }
                            })
                            this.setState({ openSnackBar: true })

                        }
                        )
                    }}
                    onRejection={() => {
                        this.setState({ confirmBox: false })
                    }}
                />}
                <div className='app-main_outer'>
                    <div className="container-fluid">
                        <div className="form-row">
                            <div className="col-md-5 card_pay">
                                <div className="form-group">
                                    <label className="py-2">Promotion</label>
                                    <TextField
                                        placeholder={'Enter Promocode'}
                                        onChange={event => {
                                            this.setState({
                                                validPromo: '',
                                                promoText: event.target.value
                                            })
                                        }}
                                    />

                                    <button onClick={() => {
                                        applyPromo(promoText,
                                            (response) => {
                                                this.setState({
                                                    snackbarData: {
                                                        variant: response.status ? 'success' : 'error',
                                                        message: 'Promo code successfully applied.' || 'error'
                                                    },
                                                    validPromo: promoText
                                                })
                                                this.setState({discount:response.data.value ? response.data.value : ''})

                                                this.setState({ discount: response.data.value })
                                                this.setState({ openSnackBar: true })
                                            },
                                            (response) => {
                                                this.setState({
                                                    snackbarData: {
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg || 'error'
                                                    }
                                                })
                                                this.setState({ openSnackBar: true })
                                            })
                                    }} type="submit"
                                        className="btn btn-sm btn-primary my-4" >
                                        Apply Code</button>
                                </div>

                                {discount && <div><p>Discount Offered: ${discountPrice} ({discount}%)</p><p>Total Payable: ${price - discountPrice}</p><br /></div>}
                            </div>
                        </div>

                        <form onSubmit={this.handleSubmit} className='stripe-payment'>                            
                            <div class="row">       
                                <div class="col-md-7">
                                    <div class="card">
                                        <div class="card-block">
                                            
                                            <div class="w-100">
                                                <div class="custom-control custom-checkbox mb-3 text-center">
                                                <input type="checkbox" name="readTerms" class="custom-control-input" id="same-address" checked={this.state.readTerms} onChange={this.handleReadTerms}/>
                                                    <label class="custom-control-label" for="same-address">I have read and agree with EasyCarRelo <a href="#" onClick={this.handlePopupTerms}>terms and conditions</a></label>
                                                </div>
                                                <div className="form-row align-items-center justify-content-center mb-3">
                                                    <div className="col-md-4 pb-3">
                                                        <div className="text-center mr-3">
                                                            <label><b>Total</b></label>
                                                            <h3>${price - discountPrice}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8 p-3 btn-primary">
                                                        <div className="text-center">
                                                            <h3 class="text-white justify-content-center" style={{fontWeight:'500'}}>Pay via Credit card</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                           

                                            <form role="form" class="w-100">
                                                <div class="form-row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Name</label>
                                                            <input type="text" class="form-control" required={true} pattern="[a-zA-Z]+((_|-| )*[a-zA-Z]*)+" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Card Holder Name" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group"> 
                                                            <label>Credit Card Number</label>
                                                            <div class="input-group"> 
                                                                    {/* <CardNumberElement
                                                                    className='form-control'
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    onFocus={handleFocus}
                                                                    onReady={handleReady}
                                                                // options={options}
                                                                // {...createOptions(this.props.fontSize)}
                                                                /> */}
                                                                <input type="text" className="form-control" required={true} value={this.state.cardNumber} pattern="[0-9]*"  placeholder="Valid Card Number" name="cardNumber" maxLength="16" onChange={this.handleChange} />
                                                                {/* <input type="text" class="form-control" name="Number" placeholder="4242 4242 4242 4242" required="" />  */}
                                                                <span class="input-group-addon">
                                                                    <i class="fa fa-credit-card"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-8">
                                                        <div class="form-group">
                                                            <label><span class="hidden-xs">EXPIRATION</span> DATE</label>
                                                            <div class="d-flex" >
                                                                <select style={{ marginRight: '10px' }} value={this.state.expMonth} required={true} name="expMonth" className="form-control"  onChange={this.handleChange}>
                                                                    <option value="">Select Month</option>
                                                                    <option value="1">january(01)</option>
                                                                    <option value="2">february(02)</option>
                                                                    <option value="3">march(03)</option>
                                                                    <option value="4">april(04)</option>
                                                                    <option value="5">may(05)</option>
                                                                    <option value="6">june(06)</option>
                                                                    <option value="7">july(07)</option>
                                                                    <option value="8">august(08)</option>
                                                                    <option value="9">september(09)</option>
                                                                    <option value="10">october(10)</option>
                                                                    <option value="11">november(11)</option>
                                                                    <option value="12">december(12)</option>
                                                                </select>
                                                                <select name="expYear" required={true} className="form-control" value={this.state.expYear} onChange={this.handleChange}>
                                                                    <option value="">Select Year</option>
                                                                     {[1, 2, 3, 4, 5, 6].map((item, index) => {
                                  return <option value={`${new Date().getFullYear() + index}`}>{new Date().getFullYear()+ index}</option>
                                })}
                                          
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-4 float-xs-right">
                                                        <div class="form-group">
                                                            <label>CVC</label>
                                                            <input type="text" required={true} name="cvc" className="form-control" value={this.state.cvc} placeholder="CVC" maxLength="4" pattern="[0-9]*" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div class="card-footer border-0">
                                            <div class="form-row">
                                                <div class="col-md-7 paymentWrap">
                                                    <div class="btn-group paymentBtnGroup btn-group-justified" data-toggle="buttons">                                                    
                                                        <label class="btn paymentMethod">
                                                            <div class="method visa active"></div>
                                                            <input type="radio" name="options" checked /> 
                                                        </label>
                                                        <label class="btn paymentMethod">
                                                            <div class="method master-card"></div>
                                                            <input type="radio" name="options" /> 
                                                        </label>
                                                        <label class="btn paymentMethod">
                                                            <div class="method amex"></div>
                                                            <input type="radio" name="options" />
                                                        </label>
                                                        <label class="btn paymentMethod">
                                                            <div class="method vishwa"></div>
                                                            <input type="radio" name="options" /> 
                                                        </label>                                                      
                                                        <label class="btn paymentMethod">
                                                            <div class="method powered"></div>
                                                            <input type="radio" name="options"/> 
                                                        </label>
                                                    </div>        
                                                </div>
                                                <div class="col-md-5">
                                                    <button class="btn btn-success btn-lg btn-block rounded-0">PROCEED</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                           
                            </div>                           
                        </form>

                        {!!cards.length && 
                        <div className="row mt-4">
                            <div className="col-md-7">
                                <div className="saved_cards rounded">
                                    <div className="savedcard_details text-capitalize">
                                        <h5>Saved card details</h5>
                                    </div>
                                    <ul>
                                    {cards.map((item, index) => {
                                        return (
                                            <li className="radio" key={index + ''}>
                                                <input name="radio" type="radio"
                                                    checked={item.id == selectedCard} />
                                                <label htmlFor="radio-1" className="radio-label"
                                                    onClick={() => {
                                                        this.setState({ selectedCard: item.id })
                                                    }}
                                                >
                                                </label>
                                                <div className="col-md-2 col-sm-2 col-2 card_type pr-0">
                                                    <img src={this.cardDetector(item.brand)} alt="ECR" className="img-fluid" width="30px" />
                                                </div>
                                                <div className="col-md-9 col-sm-9 col-9 media-body">
                                                    <h6 className='card-detail-text'>Ending with {item.last4} <br />(Expires on {item.exp_month.toString().length > 1 ? item.exp_month : `0${item.exp_month}`},{item.exp_year})</h6>
                                                </div>
                                                <div className="col-md-1 col-sm-1 col-1 remove_card_details p-0"
                                                    onClick={() => {
                                                        this.setState({
                                                            deleteItemData: item.id,
                                                            confirmBox: true
                                                        })
                                                    }}
                                                >
                                                    <img src={REMOVE_ICON} alt="ECR" className="img-fluid" width="20px" />
                                                </div>
                                            </li>
                                        )
                                    })}
                                    {/* <li className="radio">
                                        <input id="radio-2" name="radio" type="radio" />
                                        <label for="radio-2" className="radio-label">
                                            <div className="col-md-1 card_type">
                                                <img src={PAY_ICON} alt="ECR" className="img-fluid" width="30px" />
                                            </div>
                                            <div className="col-md-5 media-body">
                                                <h6>Mastercard ending in 4040 <br />(Expires in 07.22)</h6>
                                            </div>
                                            <div className="col-md-2 add_css">
                                                <form>
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder="CVV" />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-md-3 select_card_type">
                                                <span className="mb-0 text-sm">Make Default</span>
                                            </div>
                                            <div className="col-md-1 remove_card_details">
                                                <img src={REMOVE_ICON} alt="ECR" className="img-fluid" width="20px" />
                                            </div>
                                        </label>
                                    </li> */
                                    }
                                    </ul>

                                    <div className="continue_continue text-left">
                                        <button type="submit" className="btn btn-lg btn-primary"
                                            onClick={() => {

                                                const { stopLoader, startLoader, setDefaultCard } = this.props
                                                const { selectedCard } = this.state

                                                if (selectedCard) {
                                                    startLoader()
                                                    setDefaultCard(selectedCard, (response) => {
                                                    }, (response) => {
                                                        this.setState({
                                                            snackbarData: {
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg || 'error'
                                                            }
                                                        })
                                                        this.setState({ openSnackBar: true })

                                                    });
                                                    let promoData = !!this.state.validPromo ? { promoCode: this.state.validPromo } : {};


                                                    this.props.purchaseToken({
                                                        cardId: (selectedCard),
                                                        packageId: this.props.packageId,
                                                        ...promoData
                                                        // stripeToken: (payload && payload.token && payload.token.id)
                                                    },
                                                        (response) => {
                                                            this.setState({
                                                                snackbarData: {
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg || 'error'
                                                                }
                                                            })
                                                            this.setState({ openSnackBar: true })
                                                            setTimeout(() => {
                                                                this.props.history.push(ROUTES.DASHBOARD)
                                                            }, 1000)
                                                            stopLoader()
                                                        },
                                                        (response) => {
                                                            this.setState({
                                                                snackbarData: {
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg || 'error'
                                                                }
                                                            })
                                                            this.setState({ openSnackBar: true })
                                                            stopLoader()
                                                        }
                                                    )
                                                }
                                                else {
                                                    this.setState({
                                                        snackbarData: {
                                                            variant: 'error',
                                                            message: 'Please select the card.'
                                                        }
                                                    })
                                                    this.setState({ openSnackBar: true })
                                                }
                                            }}
                                            disabled={!this.state.selectedCard}
                                        >
                                            Continue </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div >
                </div>
            </>
        );
    }
}

export const Screen = injectStripe(Form)