import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import "./style.scss";

export const PendingPopup = (props) => {
  return (
    <div>
      <Dialog
        open={props.modalVisibility}
        onClose={props.getResponse}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.dialogTitle}
          <IconButton
            aria-label="close"
            onClick={props.onRejection}
            //style={{ alignSelf: "flex-end" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="pending_popup">
              <p>
              You may see a $1 pending charge on your bank statement as part of
              the card authorization process. This is a temporary authorization
              charge and it will disappear from your statement.
 </p>
              <p style={{ textDecoration: "underline" }}>
             Additional Information:
            </p>
            <ul>
              <li>
                When storing customer details, Stripe sends a request
                to the issuing bank for either a $0 or a $1 authorization to
                verify that the card is valid and the bank will allow it to be
                authorized
              </li>
              <li>
                Regardless of whether or not the authorization is
                declined, Stripe will reverse the authorization request
                immediately, but it may take 7-10 business days for the pending
                authorization to disappear from their bank statement.
              </li>
              <li>
                Stripe will first attempt a $0 authorization for Visa,
                MasterCard, and American Express card sources. If the $0
                authorization fails, then a $1 authorization will be attempted.
                Other card types will begin with a $1 authorization.
              </li>
              </ul>
              </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
