import React, { useState, useEffect } from 'react';
import { reduxForm, Field, change as changeField, initialize } from "redux-form";
import { connect } from 'react-redux';
import './styles.scss';
import validator from './validator';
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`)
const { NAME_REGX, PRICE_REGX
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const {
  CustomTooltip,
} = require(`../../../../../../components/${PLATFORM}/atoms/custom-tooltip`);
const { InsuranceList } = require('./insuranceList')

export const Screen = ({
    handleSubmit = () => { },
    onSubmit = () => { },
    data,
    userData,
    anyUpdate,
    addInsurance,
    getInsuranceList,
    initialize,
    listIndex,
    insuranceData, deleteInsurance, insuranceCount, startLoader, stopLoader, getINsurance, setInsuranceListIndex
}) => {

    useEffect(() => {
        if (anyUpdate) {
            startLoader()
            let postData = { limit: 5, index: listIndex }

            let reqq = Object.keys(postData)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(postData[k])
                ).join('&');
            getInsuranceList(reqq, () => { stopLoader() }, () => { stopLoader() })
        }
    }, [anyUpdate])

    useEffect(() => {
    }, [listIndex])

    useEffect(() => {
        startLoader()
        let postData = { limit: 5, index: listIndex }

        let reqq = Object.keys(postData)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(postData[k])
            ).join('&');
        getInsuranceList(reqq, () => {
            stopLoader()
        }, () => {
            stopLoader()
        })
        stopLoader()
    }, [])

    useEffect(() => {
    }, [insuranceData])
const [hovered, checkHover] = useState({
  first: false,
  second: false,
  third: false,
  fourth: false,
  fifth:false
});
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    return (
      <div className="app-main_outer" onClick={() => {}}>
            <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
            />
            <div className="container-fluid"
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <Form onSubmit={
                    handleSubmit(() => {
                        const { insuranceName, excess, bond, dailyFee } = data
                        addInsurance({
                            name: insuranceName,
                  excess,
                  bond,
                  dailyFee
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg || "error",
                  });
                  setOpenSnackbar(true);
                  initialize();
                  let postData = { limit: 5, index: listIndex };
                  let req = Object.keys(postData)
                    .map(
                      (k) =>
                        encodeURIComponent(k) +
                        "=" +
                        encodeURIComponent(postData[k])
                    )
                    .join("&");
                  getInsuranceList(
                    req,
                    () => {},
                    () => {}
                  );
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg || "error",
                  });
                  setOpenSnackbar(true);
                }
              );
            })}
          >
            <div className="form-fields" onLoad={() => {}}>
              <div className="branch_area mb-3">
                <div style={{ marginLeft: "-12px" }}>
                  <CustomTooltip
                    tooltipText={
                      "In this section you can list and manage all your Insurance options. This information will be displayed and available to website users."
                    }
                    hoveredKey="first"
                    checkHover={checkHover}
                    hovered={hovered}
                    style={{ left: "130px", top: "0px" }}
                  >
                    <h5 className="mb-4" style={{ marginRight: "5px" }}>
                      Insurance{" "}
                    </h5>
                  </CustomTooltip>
                </div>
                <label className="mb-4">
                  Here are your current Insurance(s):
                </label>
                <InsuranceList
                  insuranceData={insuranceData}
                  deleteInsurance={deleteInsurance}
                  insuranceCount={insuranceCount}
                  getInsuranceList={getInsuranceList}
                  getINsurance={getINsurance}
                  listIndex={(value) => {
                    setInsuranceListIndex(value)
                                }}
                                index={listIndex}
                            />
                        </div>
                        <div className="insurance_current my-3">
                            <label>Add a New Insurance</label>

                            <div className="form-row">
                                <div className="col-lg-5 col-md-6 tooltip_input">
                                    <Field
                                        name={"insuranceName"}
                                        component={Input}
                                        placeholder={"Insurance name"}
                                        type={"number"}
                                        normalize={(val, val1) => {
                                            if (NAME_REGX.test(val)) {
                                                return val
                        } else if (val === "") {
                          return val;
                        } else {
                          return val1;
                        }
                                        }}

                                        config={{
                                            type: "text",
                                        }}
                                    />
                    <CustomTooltip
                      tooltipText={
                        "Please enter here the Insurance name. For example “Basic Cover - Cars” or “Full Cover - Vans"
                      }
                      hoveredKey="second"
                      checkHover={checkHover}
                      hovered={hovered}
                      containerClass={"d-flex"}
                      imgContainerClass={"tooltip_det"}
                      style={{ left: "270px", top: "-75px", zIndex: "1000" }}
                    ></CustomTooltip>
                  </div>
                  <div className="col-lg-5 col-md-6 tooltip_input">
                    <Field
                      name={"excess"}
                      component={Input}
                      placeholder={"Excess"}
                      type={"number"}
                      normalize={(val, val1) => {
                        if (PRICE_REGX.test(val)) {
                          return val;
                        } else if (val === "") {
                          return val;
                        } else {
                          return val1;
                        }
                      }}
                                        config={{
                                            type: "text",
                                        }}
                                    />
                    <CustomTooltip
                      tooltipText={
                        "Please enter here the Excess fee. For example $3,000"
                      }
                      hoveredKey="third"
                      checkHover={checkHover}
                      hovered={hovered}
                      containerClass={"d-flex"}
                      imgContainerClass={"tooltip_det"}
                      style={{ left: "270px", top: "-55px", zIndex: "1000" }}
                    ></CustomTooltip>
                  </div>
                </div>
                            <div className="form-row">
                                <div className="col-lg-5 col-md-6 tooltip_input">
                                    <Field
                                        name={"bond"}
                                        component={Input}
                                        placeholder={"Bond"}
                                        type={"number"}
                                        normalize={(val, val1) => {
                                            if (PRICE_REGX.test(val)) {
                                                return val
                        } else if (val === "") {
                          return val;
                        } else {
                          return val1
                        }
                      }}
                      config={{
                        type: "text",
                      }}
                    />
                    <CustomTooltip
                      tooltipText={
                        "Please enter here the bond value. For example $250, $500 or $0"
                      }
                      hoveredKey="fourth"
                      checkHover={checkHover}
                      hovered={hovered}
                      containerClass={"d-flex"}
                      imgContainerClass={"tooltip_det"}
                      style={{ left: "270px", top: "55px", zIndex: "1000" }}
                    ></CustomTooltip>
                  </div>
                  <div className="col-lg-5 col-md-6 tooltip_input">
                    <Field
                      name={"dailyFee"}
                      component={Input}
                      placeholder={"Daily Fee for Excess Reduction"}
                      type={"number"}
                                        normalize={(val, val1) => {
                                            if (PRICE_REGX.test(val)) {
                                                return val
                        } else if (val === "") {
                          return val;
                        } else {
                          return val1
                        }
                      }}
                                        config={{
                                            type: "text",
                                        }}
                                    />
                    <CustomTooltip
                      tooltipText={
                        "Please enter here the Daily Fee for the Excess Reduction. For example $20"
                      }
                      hoveredKey="fifth"
                      checkHover={checkHover}
                      hovered={hovered}
                      containerClass={"d-flex"}
                      imgContainerClass={"tooltip_det"}
                      style={{ left: "270px", top: "55px", zIndex: "1000" }}
                    ></CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
                    <InputSubmit buttonLabel={"Add new insurance"} buttonStyle={"btn btn-lg btn-primary text-capitalize"}
                        buttonAction={() => {}}
                    />
                </Form>
        </div>
        </div>
    );
}

const reduxFormFunction = reduxForm({
    form: "insurance",
    onSubmitFail,
    validate: validator,
    enableReinitialize: true
})(Screen);

const mapDispatchToProps = (dispatch) => {
    return {

        changeField: (form, field, value) => {
            dispatch(changeField(form, field, value))
        },
        initialize: () => {
            dispatch(initialize())
        }
    }
}


export const Insurance = connect(null, mapDispatchToProps)(reduxFormFunction);