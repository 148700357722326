import React from "react";

export const CustomTooltip = ({
  props,
    tooltipText = '',
    hovered = '',
    hoveredKey='',
    children,
    checkHover,
  style = {},
  imgStyle='',
  after = '',
  containerClass = '',
    imgContainerClass = ""
}) =>
{
    return (
      <div
        className={
          hovered[hoveredKey] === true
            ? containerClass ? `tooltip_show ${containerClass}` : 'col d-flex tooltip_show'
            : containerClass ? `tooltip_hide ${containerClass}` : 'col d-flex tooltip_hide'
        }
      >
        {children}
        <span style={{marginTop:!imgContainerClass ? '-2px' : null,...imgStyle }}
          className={imgContainerClass ? `${imgContainerClass}` : null}
          onMouseOut={() =>
            checkHover({
              ...hovered,
              [hoveredKey]: false,
            })
          }
          onMouseOver={() =>
            checkHover({
              ...hovered,
              [hoveredKey]: true,
            })
          }
        >
          <img
            src={require("../../../../assets/driver/icons/question.svg")}
            alt="ECR"
            className="img-fluid"
            width="16px"
          />
        </span>
        {after}
        <span className="Tooltip_block" style={style}>
          {tooltipText}
        </span>
      </div>
    );

}