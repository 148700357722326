import React, { useEffect, useState } from "react";
import "./style.scss";
import { connect } from "react-redux";

const Footer = ({
  history,
  ROUTES,
  props,
  onClickAction,
  instaIcon,
  appStoreIcon,
  googlePlayIcon,
  fbIcon,
  utubeIcon,
  kiwiIcon,
}) => {
  useEffect(() => {
    setAppFooter(props.userToken);
  }, [props && props.userToken]);
  const [appFooter, setAppFooter] = useState(props && props.userToken);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let openMenu = Boolean(anchorEl);
  const [SignUpVisible, setSignupVisible] = useState(false);

  return (
    <>
      <footer className="footer">
        <div
          className={SignUpVisible === true ? "footer-bottom" : "footer-bottom"}
        >
          <div className="container">
            <div
              className="row justify-content-center"
              style={{ backgroundColor: "grey", padding: "20px" }}
            >
              <div className="col-lg-3 col-md-6 text-lg-center text-md-left pb-md-5 pb-lg-0 pb-0">
                <img src={kiwiIcon} alt="ECR" className="img-fluid" />
              </div>
              <div className="col-lg-3 col-md-6" style={{ alignSelf: "start" }}>
                <ul className="col">
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.HOW_IT_WORK);
                      }}
                    >
                      How does it work?
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.FAQ);
                      }}
                    >
                      Frequently Asked Questions
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.RENTAL_VEHICLE_AGENCIES);
                      }}
                    >
                      Rental Vehicle Agencies
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.ABOUT_US);
                      }}
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.DASHBOARD);
                      }}
                    >
                      Available Relocations
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6" style={{ alignSelf: "start" }}>
                <ul className="col">
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.TERMS);
                      }}
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.PRIVACY_POLICY);
                      }}
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.COOKIES_POLICY);
                      }}
                    >
                      Cookies Policy
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.replace(ROUTES.CANCELLATION_POLICY);
                      }}
                    >
                      Cancellation Policy
                    </a>
                  </li>
                  <li>
                    <a></a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6" style={{ alignSelf: "start" }}>
                <div className="col" style={{ alignSelf: "center" }}>
                  <ul
                    className="d-flex"
                    style={{ marginTop: "5px", marginBottom: "25px" }}
                  >
                    <li>
                      <a href="https://www.facebook.com/EasyCarRelo">
                        <img
                          src={fbIcon}
                          alt="ECR"
                          className="img-fluid"
                          width="28px"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/easycarrelo/">
                        <img
                          src={instaIcon}
                          alt="ECR"
                          className="img-fluid"
                          width="28px"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UC7R_EvU8mcYjXVKtqa_p2wg">
                        <img
                          src={utubeIcon}
                          alt="ECR"
                          className="img-fluid"
                          width="28px"
                        />
                      </a>
                    </li>
                  </ul>
                  <ul
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    <li>
                      <a href="https://apps.apple.com/nz/app/easy-car-relo/id1510284602" target={"_blank"}>
                        <img
                          src={appStoreIcon}
                          alt="ECR"
                          className="img-fluid"
                          style={{
                            width: "132px",
                          }}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.easycarrelo" target={"_blank"} style={{ marginTop: "10px", marginLeft: "1px" }}>
                        <img
                          src={googlePlayIcon}
                          alt="ECR"
                          className="img-fluid"
                          style={{
                            width: "132px",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            backgroundColor: "#3a3b3b",
            color: "white",
            height: "50px",
            padding: "10px",
          }}
        >
          Copyright 2022 EasyCarRelo. All rights reserved
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const AppFooter = connect(mapStateToProps, mapDispatchToProps)(Footer);
