import React, { useState, useEffect } from 'react';
import './styles.scss';
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    ROUTES,
    DISCOUNT_TYPE, DOWN_ICON
} = require(`../../../../../../shared/${PLATFORM}/constants`);
export const Screen = ({
    history,
    getPackages,
    packages = [],
    buyingTokenId,
    getToken = () => { },
    totalToken,
    agencyData,
    getCards,
    setTokenPrice,
    detailsNotFIlled
}) => {

    useEffect(() => {
        getPackages()
        getToken()
        getCards()
    }, [packages && packages.length])

    const [modalVisible, setModalVisible] = useState(false)
    const [editData, setEditdata] = useState({
        id: '',
        starRating: '',
        commentTodriver: "",
        commentForEcr: '',
        clickedOn: ""
    })
    const commentModalVisibility = (data) => {
        setEditdata(data)
        setModalVisible(true)
    }

const [hovered, checkHover] = useState({ first: false });


    return (
        <div className={'app-main_outer'}>

            <div className="container-fluid">
                <div className="panel-body">
                    <div className="buy_token">
              <div style={{marginLeft:'-16px'}}
              //className="col-lg-4 col-md-6 select_new_vehicle text-left"
              >
                <div
                  className={
                    hovered.first === true
                      ? "col d-flex tooltip_show"
                      : "col d-flex tooltip_hide"
                  }
                >
                  <h5 className="mb-4" style={{marginRight:'5px'}}>Buy Tokens</h5>
                  <div
                    id={"tooltip_title_show"}
                    onMouseOut={() =>
                      checkHover({
                        ...hovered,
                        first: false,
                      })
                    }
                    onMouseOver={() =>
                      checkHover({
                        ...hovered,
                        first: true,
                      })
                    }
                  >
                    <img
                      src={require("../../../../../../assets/driver/icons/question.svg")}
                      alt="ECR"
                      className="img-fluid"
                      width="16px"
                    />
                  </div>
                  <div
                    className="Tooltip_block"
                    style={{ left: "140px", top: "-15px" }}
                  >
                    In this section you can purchase tokens that will allow you
                    to list vehicles for relocation.
                  </div>
                </div>
              </div>
              <span>
                {totalToken
                  ? `You currently have ${totalToken} tokens`
                  : `You have no token`}
              </span>

                        <div className="row justify-content-center">
                            {/* <div className="col-md-4 text-center">
                                <div className="token_block">
                                    <h5>
                                        Tokens:
		                        	    <span>1</span>
                                    </h5>

                                    <div className="price_save d-flex flex-column">
                                        <label>$40</label>
                                    </div>

                                    <div className="text-center buy_btn"
                                        onClick={() => {
                                            history.push(ROUTES.PAYMENT)
                                        }}
                                    >
                                        <button className="btn btn-lg btn-primary text-capitalize">Buy Now</button>
                                    </div>
                                </div>

                            </div> */}
                            {
                                !!(packages.length) && packages.map((item, index) => {

                                    return (

                                        <div className="col-md-4 text-center" key={index + ''}>
                                            {!!item.isPopular && item.isPopular && <div className="text-center buy_title mt-3">
                                                <h5>Most Popular
                                                 <i><img src={DOWN_ICON} alt="Down Arrow" /></i>
                                                </h5>
                                            </div>}
                                            <div className={`token_block`}>
                                                <h5>
                                                    Tokens
                                                <span>{item.tokens}</span>
                                                </h5>
                                                <div className="price_save d-flex align-items-center flex-column">
                                                    <label>${item.amount}</label>
                                                    {item && item.discountValue && <span className="off">{(DISCOUNT_TYPE.PERCENTAGE === (item && item.discountType)) ? `Save ${item.discountValue}%` : `Save $${item.discountValue}`}</span>}
                                                </div>
                                                <div className="text-center buy_btn"
                                                    onClick={() => {
                                                        const { userAgency, email } = agencyData
                                                        const { address, city, name, country } = userAgency
                                                        buyingTokenId(item._id)
                                                        if (!country || !email || !address || !city || !name) {
                                                            detailsNotFIlled(true)
                                history.push(`${ROUTES.COMPANY_DETAILS}`);
                                setTokenPrice(item.amount);
                              } else {
                                setTimeout(() => {
                                  history.push(
                                    `${ROUTES.PAYMENT}?id=${item._id}`,
                                    { data: item.amount }
                                  );
                                }, 0);
                                setTokenPrice(item.amount);
                                                        }
                                                    }}
                                                >
                                                    <button className="btn btn-lg btn-primary text-capitalize">Buy Now</button>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                !packages.length &&
                                <div className="col-md-4 text-center">
                                    <div className="token_block">
                                        <h5>
                                            Tokens
                                    {/* <span>{item.tokens}</span> */}
                                        </h5>

                                        <div className="price_save d-flex align-items-center flex-column">
                                            <label>Sorry No</label>
                                            <span className="off">Package Found</span>
                                        </div>

                                        <div className="text-center buy_btn"
                                        // onClick={() => {
                                        //     buyingTokenId(item._id)
                                        //     history.push(`${ROUTES.PAYMENT}?id=${item._id}`)
                                        // }}
                                        >
                                            {/* <button className="btn btn-lg btn-primary text-capitalize">Buy Now</button> */}
                                        </div>
                                    </div>

                                </div>
                            }
                            {/* 
                            <div className="col-md-4 text-center">
                                <div className="token_block">
                                    <h5>
                                        Tokens:
		                        	    <span>10</span>
                                    </h5>

                                    <div className="price_save d-flex align-items-center flex-column">
                                        <label>$320</label>
                                        <span className="off">Save 10%</span>
                                    </div>

                                    <div className="text-center buy_btn"
                                        onClick={() => {
                                            history.push(ROUTES.PAYMENT)
                                        }}
                                    >
                                        <button className="btn btn-lg btn-primary text-capitalize">Buy Now</button>
                                    </div>
                                </div>

                            </div> */}

                        </div>

                        <span className="short_txt"><em>Please note tokens are valid for 12 months and will expire after that.</em></span>
                    </div>
                </div>
            </div>
        </div>
    )
}