import React,{useState,useEffect} from "react";
import "./style.scss";
import { connect } from "react-redux";
import { reset } from "redux-form";
const {
  defaultConfig: { PLATFORM },
} = require(`../../../../../config/default`);
const {
  GET_IN_TOUCH_IMG,
} = require(`../../../../../shared/${PLATFORM}/constants`);
const {
  stopLoader,
  startLoader,
  contactUs,
} = require(`../../../../../redux/${PLATFORM}/actions`);
const {
  SnackbarWrapper,
} = require(`../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { ContactReduxForm } = require("./form");
export const Screen = ({ startLoader, stopLoader,contactUs ,resetForm = () =>{}}) => {
  const [data,setData]=useState({username:'',email:'',phnNumber:'',driverOrAgency:'',msg:''})
  const handleChange = (e,name) => {
    setData({
      ...data,[name]:e.target.value
    })
  }
   const [snackbarData, setSnackBarData] = useState({
     variant: "",
     message: "",
   });
   const [openSnackBar, setOpenSnackbar] = useState(false);
  const [role, setRole] = useState(0);
  return (
    <>
      <SnackbarWrapper
        visible={openSnackBar}
        onClose={() => setOpenSnackbar(false)}
        variant={snackbarData.variant}
        message={snackbarData.message}
      />
    <div className="container">
      <div className="col-md-12">
       <div className="row align-items-center justify-content-center privacy_terms get_in_touchprivacy_terms get_in_touch">
          <img
            src={GET_IN_TOUCH_IMG}
            alt="ECR"
            className="img-fluid"
            width="400px"
          />
          <ContactReduxForm
            startLoader={startLoader}
            stopLoader={stopLoader}
            setRole={setRole}
            role={role}
            credentials={data}
            onSubmit={(credentials) => {
             let postData = {
                  name: credentials.name,
                  phoneNumber: credentials.phoneNumber,
                  email: credentials.email,
                  role: role === 1 ? 2 : 1,
                  message: credentials.message,
                };
              contactUs(
                postData,
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: "Message sent to ECR successfully" || "error",
                  });
                  setOpenSnackbar(true);
                    resetForm()
                },
                (response) => {
                  setSnackBarData({
                   variant: response.status ? "success" : "error",
                      message: response.msg || "error",
                  });
                  setOpenSnackbar(true);
                 }
              );
            }}
          />
        </div>
      </div>
    </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoader: () => dispatch(startLoader()),
    stopLoader: () => dispatch(stopLoader()),
    contactUs: (data, success, onError) =>
      dispatch(contactUs(data, success, onError)),
    resetForm: () => dispatch(reset("getInTouch")),
  };
};

export const ContactScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
