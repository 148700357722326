import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
const {
  defaultConfig: { PLATFORM },
} = require(`../../../../../config/default`);
const {
  startLoader,
  stopLoader,
} = require(`../../../../../redux/${PLATFORM}/actions`);
export const Screen = () => {
  return (
    <div className="rental_vehicle_agencies py-5">
      <div className="container">
        <div className="col-md-11 col-lg-9 col-12 mx-auto">
          <div className="row align-items-start justify-content-top">
            <div className="col-md-6 px-0">
              <img src={require('../../../../../assets/img/for_agencies.jpg')} alt="ECR" className="img-fluid w-100" />
            </div>
            <div className="col-md-6 rental_vehicle_agencies_content">
                    <p>Rental Vehicle Agencies</p> 
                    <p>Are you spending too much on relocating vehicles from one city to another? Are you looking for an economic way of getting your vehicles to the best                performing branches? If you have answered yes to any these questions, EasyCarRelo services can benefit you!</p>
                    <p>EasyCarRelo is a marketplace to match drivers looking for free or cheap vehicle rental and vehicle rental agencies looking to relocate vehicles from one city
                    to another.</p>
                    <p>If you are a rental vehicle agency in New Zealand and wish to take advantage of the services EasyCarRelo provide, please sign-up today in 3 easy steps:</p>
                      <ul>
                          <li>Register as an Agency on the website</li>
                          <li>Your application will be evaluated by the EasyCarRelo
                            team and we aim to give you an update within 1 to 24 hrs</li>
                          <li>	After your application is approved you can start listing 
                            your vehicles</li>
                     </ul>
                     <p>Don’t wait, sign-up today!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoader: () => dispatch(startLoader()),
    stopLoader: () => dispatch(stopLoader()),
  };
};
export const RentalVehicleAgenciesComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
