
import { REHYDRATE } from "redux-persist";
import {
    SET_PROFILE
} from '../actions';


const initialCommonState = {
    listing: [],
    profileData: ''
};

const AgencyProfileReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {

        case SET_PROFILE:
            return {
                ...state,
                profileData: action.data
            }
        case REHYDRATE:
            let common = ((action || {}).payload || {}).DashboardListingReducer || initialCommonState

            return {
                ...state,
                selectedListing: common.selectedListing,
                ...(action.payload || {}).common
            };
        default:
            return state;
    }
};

export default AgencyProfileReducer;