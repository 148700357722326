const {
  defaultConfig: { PLATFORM, LOCATION }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { VALIDATION_MESSAGES, } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = (values) => {

  const errors = {};

  if (!values[STRINGS.DRIVER_AGE]) {
    errors[STRINGS.DRIVER_AGE] = VALIDATION_MESSAGES.DRIVER_AGE_REQUIRED
  }
  if (!values[STRINGS.ESTIMATED_DISTANCE]) {
    errors[STRINGS.ESTIMATED_DISTANCE] = VALIDATION_MESSAGES.ESTIMATED_DISTANCE_REQUIRED
  }
  if (values[STRINGS.ESTIMATED_DISTANCE] && (values[STRINGS.ESTIMATED_DISTANCE] <= 0)) {
    errors[STRINGS.ESTIMATED_DISTANCE] = VALIDATION_MESSAGES.ESTIMATED_DISTANCE_GREATER_THAN_0
  }
  if ((values[STRINGS.DRIVER_AGE] && values[STRINGS.DRIVER_AGE] < 21)) {
    errors[STRINGS.DRIVER_AGE] = VALIDATION_MESSAGES.DRIVER_AGE_GREATER_THAN_15
  }
  if ((values[STRINGS.DRIVER_AGE] && values[STRINGS.DRIVER_AGE] > 100)) {
    errors[STRINGS.DRIVER_AGE] = VALIDATION_MESSAGES.DRIVER_AGE_LESS_THAN_100
  }
  if (!values[STRINGS.VEHICLES]) {
    errors[STRINGS.VEHICLES] = VALIDATION_MESSAGES.VEHICLE_REQUIRED
  }
  if (!values[STRINGS.QUANTITY_NAME]) {
    errors[STRINGS.QUANTITY_NAME] = VALIDATION_MESSAGES.QUANTITY_REQUIRED
  }
  if (values[STRINGS.QUANTITY_NAME] && (values[STRINGS.QUANTITY_NAME] < 1)) {
    errors[STRINGS.QUANTITY_NAME] = VALIDATION_MESSAGES.MIN_VALUE_1
  }
  if (!values[STRINGS.PICK_UP_LOCATION_NAME]) {
    errors[STRINGS.PICK_UP_LOCATION_NAME] = VALIDATION_MESSAGES.PICK_UP_LOCATION_REQUIRED
  }
  if (!values[STRINGS.PICK_UP_DATE_NAME]) {
    errors[STRINGS.PICK_UP_DATE_NAME] = VALIDATION_MESSAGES.PICK_UP_DATE_REQUIRED
  }


  if (values[STRINGS.REFERENCE_NAME] && (values[STRINGS.REFERENCE_NAME].length > 12)) {
    errors[STRINGS.REFERENCE_NAME] = VALIDATION_MESSAGES.REFERENCE_LENGTH_MUST_BE_LESS_THAN_13
  }

  if (!values[STRINGS.PICK_UP_TIME + '1']) {
    errors[STRINGS.PICK_UP_TIME + '1'] = VALIDATION_MESSAGES.PICK_UP_TIME_REQUIRED
  }
  if (!values[STRINGS.PICK_UP_TIME + '2']) {
    errors[STRINGS.PICK_UP_TIME + '2'] = VALIDATION_MESSAGES.PICK_UP_TIME_REQUIRED
  }
  if (!values[STRINGS.DROP_OFF_LOCATION_NAME]) {
    errors[STRINGS.DROP_OFF_LOCATION_NAME] = VALIDATION_MESSAGES.DROP_OFF_REQUIRED
  }
  if (!values[STRINGS.DROP_OFF_DATE_NAME]) {
    errors[STRINGS.DROP_OFF_DATE_NAME] = VALIDATION_MESSAGES.DROP_OFF_DATE_REQUIRED
  }
  if (!values[STRINGS.DROP_OFF_TIME + '1']) {
    errors[STRINGS.DROP_OFF_TIME + '1'] = VALIDATION_MESSAGES.DROP_OFF_TIME_REQUIRED
  }

  if (!values[STRINGS.DROP_OFF_TIME + '2']) {
    errors[STRINGS.DROP_OFF_TIME + '2'] = VALIDATION_MESSAGES.DROP_OFF_TIME_REQUIRED
  }



  return errors;
};

export default validator;
