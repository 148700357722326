import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { stopLoader, startLoader,  getCards,deleteCard,addDriverPaymentCard,setDefaultCard,makeRequest,getDriverProfileInformation } = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state, props) => {
    return {
        userToken: state.CommonReducer.userToken,
        getDriverProfileData: state.DriverReducer.getDriverProfile,
        vehicle: state.DriverReducer.getVehicleInformation,
        setVehicleDate: state.DriverReducer.setVehicleDate,
        cards: state.PaymentReducer.cards,
        paidDays: state.DriverReducer.paidDays,
        previousLocation: state.CommonReducer.prevLocation
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        getCards: () => {
            dispatch(getCards())
        },
        addCard: (data,success, onError) => {
            dispatch(addDriverPaymentCard(data, success, onError))
        },
        setDefaultCard: (id, success, failure) => {
            dispatch(setDefaultCard(id, success, failure))
        },
        makeRequest: (data, success, onError) => dispatch(makeRequest(data, success, onError)),
        deleteCard: (id, success, failure) => {
            dispatch(deleteCard(id, success, failure))
        },
        getDriverProfile: (success, onError) => dispatch(getDriverProfileInformation(success, onError)),
    }
}

export const DriverPayment = connect(mapStateToProps, mapDispatchToProps)(Screen)