
import { REHYDRATE } from "redux-persist";
import {
    SET_CARDS,
    SET_TRANSACTION_DETAILS,
    SET_TOKEN_PRICE
} from '../actions';


const initialCommonState = {
    cards: [],
    transactions: [],
    tokenPrice:0
};

const PaymentReducer = (state = { ...initialCommonState }, action) => {
    switch (action.type) {
        case SET_TRANSACTION_DETAILS:
            return {
                ...state, transactions: action.data
            }
        case SET_CARDS:
            return {
                ...state, cards: action.data
            }
        case SET_TOKEN_PRICE:
            return {
                ...state,tokenPrice:action.data
            }

        case REHYDRATE:
            let common = ((action || {}).payload || {}).PaymentReducer || initialCommonState
            return {
                ...state,
                cards: common.cards,
                tokenPrice:common.tokenPrice,
                ...(action.payload || {}).common
            };
        default:
            return state;
    }
};

export default PaymentReducer;