import React from "react";
import "./style.scss";

export const PrivacyPolicyComponent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 privacy_terms">
          <p style={{ fontSize: "18px", fontWeight: "bold" }}>Privacy Policy</p>
          <hr />
          <p>
            Your privacy is important to us. It is EasyCarRelo' policy to
            respect your privacy regarding any information we may collect from
            you across our website, www.EasyCarRelo.co.nz, and other sites and
            mobile apps we own and operate.
          </p>

          <p>
            If you are outside New Zealand, by accessing this website, including
            its web pages, you agree that any Personal Information you provide
            to us is subject to this Policy and the New Zealand Privacy Act 1993
            (Privacy Act).
          </p>

          <p>
            By giving us this information, you consent to this information being
            collected, used, disclosed, transferred to New Zealand and stored by
            us.
          </p>

          <p>
            By using our website www.EasyCarRelo.co.nz, you agree to the terms
            of this Policy.
          </p>

          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            1. INFORMATION WE COLLECT
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>LOG DATA</p>
          <p>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            computer’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details.
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>DEVICE DATA</p>
          <p>
            We may also collect data about the device you’re using to access our
            website. This data may include the device type, operating system,
            unique device identifiers, device settings, and geo-location data.
            What we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            PERSONAL INFORMATION
          </p>
          <p>We may ask for personal information, such as:</p>
          <ul>
            <li>
              Your full name, date of birth, physical address, email address,
              phone number, mobile number;
            </li>
            <li>
              Payment Information such as credit card number and billing
              address.
            </li>
          </ul>
          <p>
            Providing some information is optional. If you choose not to enter
            your personal information, we'll be unable to provide you with our
            services.
          </p>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            2. LEGAL BASES FOR PROCESSING
          </p>
          <p>
            We will process your personal information lawfully, fairly and in a
            transparent manner according to the New Zealand Privacy Act (1993).
            We collect and process information about you only where we have
            legal bases for doing so.
          </p>
          <p>
            These legal bases depend on the services you use and how you use
            them, meaning we collect and use your information only where:
          </p>
          <ul>
            <li>
              it’s necessary for the performance of a vehicle reservation
              request to which you are a party or to take steps at your request
              before entering into such activity (for example, when you request
              a vehicle relocation within our website or app);
            </li>
            <li>
              it satisfies a legitimate interest (which is not overridden by
              your data protection interests), such as for research and
              development, to market and promote our services, and to protect
              our legal rights and interests;
            </li>
            <li>
              you give us consent to do so for a specific purpose (for example,
              you might consent to us sending you our newsletter); or
            </li>
            <li>
              we need to process your data to comply with a legal obligation.
            </li>
          </ul>
          <p>
            Where you consent to our use of information about you for a specific
            purpose, you have the right to change your mind at any time (but
            this will not affect any processing that has already taken place).
          </p>

          <p>
            We don’t keep personal information for longer than is necessary.
            While we retain this information, we will protect it within
            commercially acceptable means (Secure Servers, Encrypted
            Transmission of Data including Credit Card details) to prevent loss
            and theft, as well as unauthorized access, disclosure, copying, use
            or modification. That said, we advise that no method of electronic
            transmission or storage is 100% secure and cannot guarantee absolute
            data security. If necessary, we may retain your personal information
            for our compliance with a legal obligation or in order to protect
            your vital interests or the vital interests of another natural
            person.
          </p>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            3. COLLECTION AND USE OF INFORMATION
          </p>
          <p>
            Your Personal Information is collected by us when you make an
            enquiry or a request for booking for a vehicle relocation. This can
            be made via this website, affiliated websites, travel agents, third
            party websites and apps, by phone (including the recording of calls
            for training and customer service reasons and to help you and us
            meet our contractual obligations) or by email. Your Personal
            Information may also be collected from third parties you authorise
            us to contact, or as otherwise permitted by the New Zealand Privacy
            Act 1993.
          </p>

          <p>
            Third party payment solutions are also used by us. This is necessary
            due to the nature of the rental vehicle business
          </p>
          <p>
            We may also collect your personal information that is made publicly
            available on social media sites, apps and widgets, such as but not
            limited to Facebook, Instagram, Twitter and YouTube. The use of
            these websites and apps are entirely optional and you can choose not
            to. All such third party websites, apps and widgets are governed by
            the privacy policies and practices of the third party providers, for
            which we are not responsible.
          </p>
          <p>
            We may use and disclose information for the following purposes and
            personal information will not be further processed in a manner that
            is incompatible with these purposes:
          </p>
          <ul>
            <li>To process your vehicle relocation booking request;</li>
            <li>
              To communicate with you about your rental or account and provide
              customer support - This includes sending you emails pre and
              post-booking including feedback surveys;
            </li>
            <li>
              To bill and collect outstanding amounts owed to us. This includes
              sending you emails, invoices, receipts, outstanding notices,
              issues with your bank card (Credit Card or Debit Card). We use
              third parties services for secure credit card payment processing,
              and we send billing information to those third parties to process
              your bookings and credit card payments;
            </li>
            <li>
              To meet legal requirements, including complying with New Zealand
              court orders, NZTA requests, NZ Police requests and other
              appropriate legal mechanisms;
            </li>
            <li>
              To prosecute and defend a court, arbitration, or similar legal
              proceeding;
            </li>
            <li>
              To provide information to advisors or representatives, including
              lawyers, consultants and accountants, to help us comply with
              legal, accounting and security requirements;
            </li>
            <li>
              To send you alert and update messages. We may inform you of
              temporary or permanent changes to our services, such as new
              features and changes to our Privacy Policy;
            </li>
            <li>
              To enable you to access and use our website, associated
              applications and associated social media platforms;
            </li>
            <li>For internal record keeping and administrative purposes;</li>
            <li>
              For analytics, market research and business development, including
              to operate and improve our website, associated applications and
              associated social media platforms;
            </li>
            <li>
              To run competitions and/or offer additional benefits to you;
            </li>
            <li>
              For advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you;
            </li>
            <li>To consider your employment application.</li>
          </ul>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            4. DISCLOSURE OF PERSONAL INFORMATION TO THIRD PARTIES
          </p>
          <p>We may disclose personal information to:</p>
          <ul>
            <li>
              rental vehicle agencies that provide the vehicles to be relocated.
              Your personal information such as Full Name, Date of Birth, Phone,
              Email address and Location will be shared;
            </li>
            <li>
              third party service providers for the purpose of enabling them to
              provide their services, including (without limitation), IT service
              providers, data storage, hosting and server providers, ad
              networks, analytics, error loggers, debt collectors, maintenance
              or problem-solving providers, marketing or advertising providers,
              professional advisors and payment systems operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>sponsors or promoters of any competition we run;</li>
            <li>
              credit reporting agencies, courts, tribunals and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you;
            </li>
            <li>
              courts, tribunals, regulatory authorities and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise
              or defend our legal rights;
            </li>
            <li>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services or direct marketing
              to you; and
            </li>
            <li>third parties to collect and process data.</li>
          </ul>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            5. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION
          </p>
          <p>
            The personal information we collect is stored and processed in New
            Zealand, or where we or our partners, affiliates and third-party
            providers maintain facilities. By providing us with your personal
            information, you consent to the disclosure to these overseas third
            parties.
          </p>

          <p>
            Where we transfer personal information from a non-EEA country to
            another country, you acknowledge that third parties in other
            jurisdictions may not be subject to similar data protection laws to
            the ones in our jurisdiction. There are risks if any such third
            party engages in any act or practise that would contravene the data
            privacy laws in our jurisdiction and this might mean that you will
            not be able to seek redress under our jurisdiction’s privacy laws.
          </p>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            6. YOUR RIGHTS AND CONTROLLING YOUR PERSONAL INFORMATION
          </p>
          <p>
            Choice and consent: By providing personal information to us, you
            consent to us collecting, holding, using and disclosing your
            personal information in accordance with this privacy policy and the
            Privacy Act 1993.
          </p>
          <p>
            Information from third parties: Please do not provide us with
            personal information about others without their consent to do so. 
            If we receive personal information about you from a third party, we
            will protect it as set out in this privacy policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.
          </p>
          <p>
            Restrict: You may choose to restrict the collection or use of your
            personal information. If you have previously agreed to us using your
            personal information for direct marketing purposes (for example,
            sending you email newsletter), you may change your mind at any time
            by contacting us using the details below. If you ask us to restrict
            or limit how we process your personal information, we will let you
            know how the restriction affects your use of our website or products
            and vehicle and trailer rental services.
          </p>
          <p>
            Access and data portability: You may request details of the personal
            information that we hold about you. You may request a copy of the
            personal information we hold about you. Where possible, we will
            provide this information on a readable machine format. You may
            request that we erase the personal information we hold about you at
            any time. You may also request that we transfer this personal
            information to another third party. We will only retain and use your
            information in such a case as necessary to comply with our legal
            obligations, resolve disputes, and enforce our agreements.
          </p>
          <p>
            Correction: If you believe that any information we hold about you is
            inaccurate, out of date, incomplete, irrelevant or misleading,
            please contact us using the details below. We will take reasonable
            steps to correct any information found to be inaccurate, incomplete,
            misleading or out of date.
          </p>
          <p>
            Notification of data breaches: We will comply with the New Zealand
            Privacy Act 1993 and applicable New Zealand laws in respect of any
            data breach.
          </p>
          <p>
            Complaints: If you believe that we have breached the Privacy Act and
            wish to make a complaint, please contact us using the details below
            and provide us with full details of the alleged breach. We will
            promptly investigate your complaint and respond to you, in writing
            by email, setting out the outcome of our investigation and the steps
            we will take to deal with your complaint. You also have the right to
            contact a regulatory body or data protection authority in relation
            to your complaint.
          </p>
          <p>
            Unsubscribe: To unsubscribe from our e-mail database or opt-out of
            communications (including marketing communications), please contact
            us using the details below or opt-out using the opt-out facilities
            provided in the communication.
          </p>

          <p style={{ fontSize: "14px", fontWeight: "bold" }}>7. SECURITY</p>
          <p>
            The security of your personal information is important to us and it
            is required by The Privacy Act. We take reasonable steps to protect
            and secure your personal information
          </p>
          <p>
            When you enter your credit card information on our website booking
            widget, the transmission of data is encrypted using secure socket
            layer technology (SSL).
          </p>
          <p>
            Our staff members are aware of the obligations under the Privacy Act
            and required to respect the confidentiality of personal information
            and the privacy of our customers.
          </p>
          <p>
            We don’t keep personal information for longer than is necessary.
            While we retain this information, we will protect it within
            commercially acceptable means (Secure Servers, Encrypted
            Transmission of Data) to prevent loss and theft, as well as
            unauthorised access, disclosure, copying, use or modification. That
            said, we advise that no method of electronic transmission or storage
            is 100% secure and cannot guarantee absolute data security.
          </p>
          <p style={{ fontSize: "14px", fontWeight: "bold" }}>8. COOKIES</p>
          <p>
            We use “cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content
            based on preferences you have specified. Please refer to our Cookie
            Policy for more information.
          </p>

          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            9. BUSINESS TRANSFERS
          </p>
          <p>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data among
            the assets transferred to any parties who acquire us. You
            acknowledge that such transfers may occur and that any parties who
            acquire us may continue to use your personal information according
            to this policy.
          </p>

          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            10. LIMITS OF OUR POLICY
          </p>
          <p>
            Our websites or apps may link to external sites that are not
            operated by us. Please be aware that we have no control over the
            content and policies of those sites, and cannot accept
            responsibility or liability for their respective privacy practices.
          </p>

          <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            11. CHANGES TO THIS POLICY
          </p>
          <p>
            At our discretion, we may change our privacy policy to reflect
            current acceptable practices. We will take reasonable steps to let
            users know about changes via our website. Your continued use of this
            site after any changes to this policy will be regarded as acceptance
            of our practices around privacy and personal information.
          </p>
          <p>
            If we make a significant change to this privacy policy, for example
            changing a lawful basis on which we process your personal
            information, we will ask you to re-consent to the amended privacy
            policy.
          </p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>EasyCarRelo</p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>P.O.Box 91-765</p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            Victoria Street West
          </p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            Auckland, New Zealand
          </p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            info@easycarrelo.co.nz
          </p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>0800 820 824</p>
          <p style={{ fontSize: "16px"}}>
            This policy is effective as of 15th January 2021.
          </p>
        </div>
      </div>
    </div>
  );
};
