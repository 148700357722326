
import {
    SAVE_SEARCHED_DRIVERS
} from '../actions';


const initialCommonState = {
    savedDrivers: []
};

const InviteDriverReducer = (state = { ...initialCommonState }, action) => {
    switch (action.type) {
        case SAVE_SEARCHED_DRIVERS:
            return {
                ...state, savedDrivers: action.data
            }
        // case REHYDRATE:
        //     let common = ((action || {}).payload || {}).DriverReducer || initialCommonState
        //     return {
        //         ...state,
        //         currentLocation: common.currentLocation,
        //         advanceSearchFormValues: common.advanceSearchFormValues,
        //         pickup_Data: common.pickup_Data,
        //         vehicleListing: common.vehicleListing,
        //         getVehicleInformation: common.getVehicleInformation,
        //         setVehicleDate: common.setVehicleDate,
        //         range: common.range,
        //         paidDays: common.paidDays,
        //         ...(action.payload || {}).common
        //     };
        default:
            return state;
    }
};

export default InviteDriverReducer;