
import { REHYDRATE } from "redux-persist";
import {
    SET_LISTING,
    SELECTED_LIST,
} from '../actions';


const initialCommonState = {
    listing: [],
    selectedListing: ''
};

const DashboardListingReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {

        case SET_LISTING:
            return {
                ...state,
                listing: action.data
            }
        case SELECTED_LIST:
            return {
                ...state,
                selectedListing: action.data
            }
        case REHYDRATE:
            let common = ((action || {}).payload || {}).DashboardListingReducer || initialCommonState

            return {
                ...state,
                selectedListing: common.selectedListing,
                ...(action.payload || {}).common
            };
        default:
            return state;
    }
};

export default DashboardListingReducer;