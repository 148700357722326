import React from "react";
import "./style.scss";
import { connect } from "react-redux";
const {
  defaultConfig: { PLATFORM },
} = require(`../../../../../config/default`);
const {
  startLoader,
  stopLoader,
} = require(`../../../../../redux/${PLATFORM}/actions`);
export const Screen = () => {
  return (
    <div className="about_us py-5">
      <div className="container">
        <div className="col-md-11 col-lg-9 col-12 mx-auto">
          <div className="row align-items-start justify-content-top">
            <div className="col-md-6 px-0">
              <img src={require('../../../../../assets/img/about_us.jpg')} alt="ECR" className="img-fluid w-100" />
            </div>
            <div className="col-md-6 about_us_content">
              <p>EasyCarRelo is kiwi owned and operated. Our services were made available to the public in February 2021 and the team is excited to help drivers looking for free or low cost vehicle rental and vehicle rental agencies looking to save on their relocation costs. If you are a driver or a rental vehicle agency, don’t wait, sign-up today! If you wish to contact us please write to us here</p>
              <div className="py-3">
                <img src={require('../../../../../assets/icons/kiwi_vector_black.png')} alt="ECR" className="img-fluid" width="150"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    startLoader: () => dispatch(startLoader()),
    stopLoader: () => dispatch(stopLoader()),
  };
};
export const AboutUsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen);
