import React from 'react';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const { defaultConfig: { PLATFORM, LOCATION, VERSION } } = require(`../../../../config/default`);
const {
  ROUTES,
  ACCOUNT_ICON,
  HAMBURGER_ICON,
  OUTER_LOGO_ICON,
  FACEBOOK_FOOTER_ICON,
  INSTA_ICON,
  KIWI_ICON,
  IOS_APP_STORE,
  GOOGLE_PLAY_ICON,
  UTUBE_ICON,
} = require(`../../../../shared/${PLATFORM}/constants`);
const { LoginScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/login`);
const { ForgotScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/forgot-password`);
const { SignupScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/sign-up`)
const { HomeScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/driver-views/home`);
const {
  AppFooter,
} = require(`../../../../components/${PLATFORM}/atoms/app-footer`);
const { AppHeader } = require(`../../../../components/${PLATFORM}/atoms/app-header`)
const { SearchVehicleList } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/driver-views/search-vehicle-list`)
const { ViewVehicle } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/driver-views/view-vehicle`);
const { FaqComponent } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/faq`)
const { HowItWorkComponent } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/how-it-works/screen.js`)
const {
  RentalVehicleAgenciesComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/rental-vehicle-agencies/screen.js`);
const {
  AboutUsComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/about-us/screen.js`);
const {
  TermsComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/terms&conditions`);
const {
  PrivacyPolicyComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/privacy-policy`);
const {
  CookiesPolicyComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/cookies-policy`);
const {
  CancellationPolicyComponent,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/cancellation-policy`);
const {
  ContactScreen,
} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/get-in-touch/screen`);

const AuthRouter = (props) => {
    let history = useHistory()

    return (
        <React.Fragment>
            <AppHeader
                companyLogo={OUTER_LOGO_ICON}
                accountIcon={ACCOUNT_ICON}
                hamburgerIcon={HAMBURGER_ICON}
                history={history}
                ROUTES={ROUTES}
                props={props} />
            <Switch>
                <Route path={ROUTES.REGISTER} render={() => { return <SignupScreen /> }} />
                <Route path={ROUTES.LOGIN} component={LoginScreen} />
                <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotScreen} />
                <Route path={ROUTES.DASHBOARD} component={HomeScreen} {...props} />
                <Route exact path={ROUTES.ROOT} component={HomeScreen} {...props} />
                <Route path={ROUTES.SEARCH_VEHICLE_LIST} component={SearchVehicleList} {...props} />
                <Route path={ROUTES.VIEW_VEHICLE} component={ViewVehicle} {...props} />
                <Route path={ROUTES.FAQ} component={FaqComponent} />
                <Route path={ROUTES.HOW_IT_WORK} component={HowItWorkComponent} />
                <Route path={ROUTES.TERMS} component={TermsComponent} />
                <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyComponent} />
                <Route path={ROUTES.COOKIES_POLICY} component={CookiesPolicyComponent}/>
                <Route path={ROUTES.CANCELLATION_POLICY} component={CancellationPolicyComponent}/>
                <Route path={ROUTES.GET_IN_TOUCH} component={ContactScreen}/>
               <Route path={ROUTES.ABOUT_US} component={AboutUsComponent} />
               <Route path={ROUTES.RENTAL_VEHICLE_AGENCIES} component={RentalVehicleAgenciesComponent} />

                <Redirect
            to={{
              pathname: ROUTES.DASHBOARD,
              state: { from: history.location.pathname },
            }}
          />
        </Switch>
        <AppFooter
          instaIcon={INSTA_ICON}
          appStoreIcon={IOS_APP_STORE}
          googlePlayIcon={GOOGLE_PLAY_ICON}
          fbIcon={FACEBOOK_FOOTER_ICON}
          utubeIcon={UTUBE_ICON}
          kiwiIcon={KIWI_ICON}
          history={history}
          ROUTES={ROUTES}
          props={props}
        />
        </React.Fragment>
    );
}

export default (AuthRouter);