import React from 'react';
import moment from 'moment';
import { ActionDropDown } from '../action-dropdown';
import classNames from 'classnames';
import './style.scss';
import { useState, useEffect } from 'react';
const { defaultConfig: { PLATFORM } } = require(`../../../../config/default`);
const filterSvg = require("../../../../assets/icons/filter.svg")
const { StarRatingComponent } = require(`../../../../components/${PLATFORM}/atoms/star-component`)
const {
  DialogModal,
} = require(`../../../../components/${PLATFORM}/atoms/dialog-component`);
const {
  PAGE_TITLES,
  ROUTES,
  DASHBOARD_LISTING,
  LAYOUTS,
  ACTION_MENU,
  BOOKING_STATUS,
  DATE_FORMAT,
  LABELS,
  SETTING_ICON,
  DASHBOARD_LISTING_TABLE
} = require(`../../../../shared/${PLATFORM}/constants`);
export const CustomTable = ({
    // deleteRateNow="",
    // deleteRateNow=()=>{},
    deleteAgency=()=>{},
    history="",
    rateAgencyByDriver=()=>{} ,
    tableHeader = [],
    visibility = false,
    onActionClick = () => { },
    commentModal = false,
    setCommentModal = () => { },
    tableData = [],
    indexToOpen,
    onEdit = () => { },
    tableType,
    setModalVisible = () => { },
    dropDownMenu,
    tablePage,
    DASHBOARD_LISTING_TABLE = [],
    BOOKING_STATUS = {},
    DATE_FORMAT = 'DD/MM/YYYY',
    changeStatusDashboard = () => { },
    onDelete = () => { },
    onRowClick = () => { },
    onFavoriteChange = () => { },
    onCopy = () => { },
    anyUpdate,
    onShareAndInvite = () => { },
    onBooking = () => { },
    settingIcon,
    sort = () => { },
    sorting="driver",
    ratedByDriver = false,
    onStatusChange = () => { },
    onClickDriverDetails = () => { },
    DRIVER_RATING_STATUS,
    getDashboardTableData ,
    userData = {}
}) => {
  console.log(rateAgencyByDriver, "rateAgencyByDriver", getDashboardTableData)
  const [snackbarData, setSnackBarData] = useState({
      variant: '',
      message: ''
  });
//   const deleteRateNow = (payload)=> {
//     console.log(payload, "payload", rateAgencyByDriver)
//     let postData = {
//         id: payload._id,
//         rateForDriver: "0"
//       }
//       rateAgencyByDriver(postData, (response) => {
//             setSnackBarData({
//                 variant: response.status ? 'success' : 'error',
//                 message: `Successfully deleted ${content.agencyName ? content.agencyName : ''}`
//             });
//             setOpenSnackbar(true)
//             setTimeout(() => {
//                 getResponse()
//                 // console.log(tripIndex)
//                 let data = { limit: STRINGS.TRIP_LIMIT, index: tripIndex && tripIndex.past }
//                 let pastTripReq = Object.keys(data)
//                     .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
//                     ).join('&');
//                 getDriverPastTrip(pastTripReq, () => { }, () => { })
//             }, 2000);

//         }, (error) => {
//             setSnackBarData({
//                 variant: error.status ? 'success' : 'error',
//                 message: error.msg || 'error'
//             });
//             setOpenSnackbar(true)
//             setTimeout(() => {
//                 getResponse()
//             }, 2000);

//         })

// }

    const getData = (statusCode) => {
        for (let index in DASHBOARD_LISTING_TABLE) {
            if (DASHBOARD_LISTING_TABLE[index] && DASHBOARD_LISTING_TABLE[index].statusCode === statusCode) {
                return DASHBOARD_LISTING_TABLE[index].elementLabel
            }
        }
    }
    const [actionMenuIndex, setActionMenuIndex] = useState(null)
    const [actionMenuItem, setActionMenuItem] = useState({})
    const [sortOrder, setSortOrder] = useState(1)
  const [commentData, setCommentData] = useState('');
    useEffect(() => {
        var element = document.getElementsByTagName('body')[0]
        var hs = element.scrollWidth > element.clientWidth;
        if (hs === true) {
            element.scrollLeft += 500
            element.scrollTop = element.scrollHeight;
        }
    }, [visibility])

    const sortData = (item) => {
      let sortKey
      if(item.id==="vehicle"){
        sortKey="vehicleName"
      }
      else if(item.id==="pickupLocation"){
        sortKey="pickUpLocation"
      }
      else if(item.id==="pickupDate"){
        sortKey="pickUpDate"
      }
      else if(item.id==="dropoffLocation"){
        sortKey="dropOffLocation"
      }
      else if(item.id==="dropoffDate"){
        sortKey="dropOffDate"
      }
      else if(item.id==="listingData"){
        sortKey="listingDate"
      }
      else if(item.id==="lastUpdated"){
        sortKey="lastUpdated"
      }
      else{
        sortKey="id"
      }
      getDashboardTableData({
        agencyId: userData && userData.userAgency && userData.userAgency._id,
        limit: 10 , index: 0,
        sortKey:sortKey,
        sortOrder:sortOrder 
    }, (response) => {
      if(response.status===200){
        if(sortOrder===1){
          setSortOrder(-1)
        }
        else{
          setSortOrder(1)
        }
      }
    }
    , () => {})
    }
    return (
      <div className={actionMenuIndex === indexToOpen && visibility ? "drop_scroll table-responsive table-default my-2" : "table-responsive table-default my-2"}>
        {commentModal && 
          <DialogModal
                    dialogContent={commentData}
                    dialogTitle={'Comments'}
                    modalType={'Comment'}
                    modalVisibility={commentModal}
                    toggleDialogModal={() => setCommentModal(!commentModal)}
                    onRejection={() => {
                    setCommentModal(false)
                }}
            />}
            <table className="dash_td table table-borderless">
                <thead>
                    <tr>
                        {tableHeader.map((item, index) => {
                            return (
                                <th key={index + ''}
                                    className={!!item.class ? item.class : ''}
                                    onClick={() => {
                                        if ((tableData && tableData.length) > 1) {
                                            sort(item.id)
                                        }

                                    }}>{(item.label==="Vehicle"||item.label==="Pick-up Location"||item.label==="Pick-up Date"||item.label==="Drop-off Location"||item.label==="Drop-off Date"||item.label==="Listing Date"||item.label==="Last Updated"||item.label==="Id")?<div className='filter'><span>{item.label}</span><div onClick={()=>sortData(item)}><i><img src={filterSvg}/></i></div></div>:<span>{item.label}</span>}
                                    {/* <img src={require('../../../../assets/icons/sort.png')} alt="ECR" className="img-fluid" width="16px" /> */}
                                </th>
                            )
                        })
                        }
                    </tr>
                </thead>
                {
                    !!(tableData && tableData.length) && tableData.map((item, index) => {
                        return (
                          <tbody
                            key={index + ""}
                            className={
                              !(tablePage === "driverRating") ? "cursor" : ""
                            }
                          >
                            <tr
                              onClick={() => {
                                onRowClick(item);
                              }}
                            >
                              {!(tablePage === "driverRating") ? (
                                <td>
                                  <span
                                    className={classNames([
                                      "badge badge-outline",
                                      {
                                        "border-success avail":
                                          item.status ===
                                          BOOKING_STATUS.AVAILABLE,
                                      },
                                      {
                                        "border-primary booked":
                                          item.status === BOOKING_STATUS.BOOKED,
                                      },
                                      {
                                        "border-danger cancel":
                                          item.status ===
                                            BOOKING_STATUS.CANCELLED_BY_DRIVER ||
                                          item.status ===
                                            BOOKING_STATUS.CANCELLED_BY_AGENCY,
                                      },
                                      {
                                        "border-secondary expire":
                                          item.status === BOOKING_STATUS.EXPIRE,
                                      },
                                      {
                                        "border-complete":
                                          item.status ===
                                          BOOKING_STATUS.COMPLETE,
                                      },
                                      {
                                        "border-request":
                                          item.status ===
                                          BOOKING_STATUS.PENDING,
                                      },
                                      {
                                        "border-warning":
                                          item.status ===
                                          BOOKING_STATUS.NO_SHOW,
                                      },
                                    ])}
                                  >
                                    {getData(item.status) || "--"}
                                  </span>
                                </td>
                              ) : (
                                <td className="text-capitalize">
                                  {item.driverName || "--"}
                                </td>
                              )}
                              <td className="text-capitalize" onClick={()=>history.push(`${ROUTES.VIEW_LISTING}?id=${item._id}`)}>
                                {(item.vehicleData && item.vehicleData.name) ||
                                  item.vehicle ||
                                  "--"}
                              </td>
                              {!(tablePage === "driverRating") ? (
                                <td>{item.vehicleQuantity || "--"}</td>
                              ) : (
                                <td>{item.rego || "--"}</td>
                              )}
                              {!(tablePage === "driverRating") ? (
                                <td>
                                  {item.status === 6 ||
                                  item.status === 2 ||
                                  item.status === 3 ? (
                                    <div className='customerInfo'
                                      // onClick={() => onClickDriverDetails(item)}
                                      onClick={() => { console.log(item)
                                        onClickDriverDetails(item)}}
                                    >
                                      <i>
                                      <img
                                        src={require("../../../../assets/icons/account_icon.svg")}
                                        alt="ECR"
                                        className="img-fluid"
                                        width="16px"
                                      />
                                      </i>
                                      <span>Customer info</span>
                                    </div>
                                  ) : (
                                    <div>--</div>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}
                              <td className="text-capitalize">
                                {(item &&
                                  item.pickupBranchData &&
                                  item.pickupBranchData.name) ||
                                  (item && item.pickupLocation) ||
                                  "--"}
                              </td>
                              {(!(tablePage === "driverRating") &&
                                item.status === BOOKING_STATUS.PENDING) || item.status === BOOKING_STATUS.BOOKED ||
                            item.status === BOOKING_STATUS.COMPLETE ? (
                                <td>
                                  {moment(new Date(item.startDate? item.startDate: item.pickupDate)).format(DATE_FORMAT) || "--"}
                                </td>
                              ) : (
                                <td>
                                  {moment(new Date(item.startDate? item.startDate:  item.pickupDate
                                    )
                                  ).format(DATE_FORMAT) || "--"}
                                </td>
                              )}
                              {/* <td>
                                {moment(new Date(item.pickupDate)).format(
                                  DATE_FORMAT
                                ) || "--"}
                              </td> */}
                              <td>
                                {(item &&
                                  item.dropoffBranchData &&
                                  item.dropoffBranchData.name) ||
                                  (item && item.dropoffLocation) ||
                                  "--"}
                              </td>
                              {(!(tablePage === "driverRating") &&
                                item.status === BOOKING_STATUS.PENDING) || item.status === BOOKING_STATUS.BOOKED ||
                              item.status === BOOKING_STATUS.COMPLETE ? (
                                <td>
                                  {moment(new Date(item.endDate ? item.endDate : item.dropoffDate
                                    )
                                  ).format(DATE_FORMAT) || "--"}
                                </td>
                              ) : (
                                <td>
                                  {moment(new Date(item.endDate ? item.endDate : item.dropoffDate
                                    )
                                  ).format(DATE_FORMAT) || "--"}
                                </td>
                              )}
                              {/* <td>
                                {moment(new Date(item.dropoffDate)).format(
                                  DATE_FORMAT
                                ) || "--"}
                              </td> */}
                              {!(tablePage === "driverRating") && (
                                <>
                                  <td>
                                    {moment(new Date(item.createdAt)).format(
                                      DATE_FORMAT
                                    ) || "--"}
                                  </td>
                                  <td>
                                    {moment(new Date(item.updatedAt)).format(
                                      DATE_FORMAT
                                    ) || "--"}
                                  </td>
                                </>
                              )}
                              {tableType === "ratedTable" && !ratedByDriver && (
                                <td>
                                  {" "}
                                  {moment(new Date(item.lastUpdated)).format(
                                    DATE_FORMAT
                                  ) || "--"}
                                </td>
                              )}
                              {!(tablePage === "driverRating") && (
                                <td>{item.operator || "--"}</td>
                              )}
                              {!(tablePage === "driverRating") && (
                                <td>{item.reference || "--"}</td>
                              )}
                              <td>
                                {(!!item.bookingId ? item.bookingId : `--`) ||
                                  "XYZ123" ||
                                  "--"}{" "}
                              </td>
                              {tablePage === "driverRating" && !ratedByDriver && (
                                <td>
                                  {
                                    <div className="checkbox_block">
                                      <label className="form-checkbox">
                                        <input
                                          name="subscribe"
                                          checked={item.isFavorite}
                                          type="checkbox"
                                          className="form-check-input"
                                          value=""
                                          onChange={() => {}}
                                          onClick={() => {
                                            onFavoriteChange({
                                              isFavorite: !item.isFavorite,
                                              id: item._id,
                                            });
                                          }}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  }
                                </td>
                              )}

                              {tableType === "yetToRate" ? (
                                <td style={{display: "flex", }}>
                                  <li style={{marginRight: "5px"}}>
                                    <a
                                      className="link_color"
                                      onClick={() => {
                                        setModalVisible({
                                          id: item._id,
                                          clickedOn: "rate now",
                                        });
                                      }}
                                    >
                                      Rate now
                                    </a>
                                  </li>
                                    <li>
                                      <button
                                        className="link_color"
                                        style={{color: "red", background: "none", border: "none", marginLeft: "5px", }}
                                        onClick={()=> {
                                          console.log("isndetable", item)
                                          deleteAgency(item)
                                          // debugger;
                                          // deleteRateNow(item)
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </li>
                                </td>
                              ) : tableType === "ratedTable" ? (
                                <>
                                  <td>
                                    <div className="rate_stars">
                                      <StarRatingComponent
                                        initialRating={item.starRating}
                                        readonly={true}
                                      />
                                    </div>
                                  </td>
                                  {!ratedByDriver && (
                                    <td>
                                      <li>
                                        <a
                                          className="link_color"
                                          onClick={() => {
                                            setModalVisible({
                                              id: item._id,
                                              starRating: item.starRating,
                                              commentTodriver:
                                                item.commentForDriver,
                                              commentForEcr:
                                                item.commentForECRByAgency,
                                              clickedOn: "view/edit",
                                            });
                                          }}
                                        >
                                          View/edit
                                        </a>
                                      </li>
                                    </td>
                                  )}
                                </>
                              ) : (
                                !ratedByDriver && (
                                  <td>
                                    <div
                                      className="edit_action"
                                      id="edit_icon"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <i
                                        className="edit_icon"
                                        onClick={() => {
                                          onActionClick(index);
                                          setActionMenuIndex(index);
                                          setActionMenuItem(item);
                                        }}
                                      >
                                        <img
                                          src={settingIcon}
                                          alt="ECR"
                                          className="img-fluid"
                                          width="34px"
                                        />
                                      </i>
                                      {index === indexToOpen && visibility && (
                                        <ActionDropDown
                                          BOOKING_STATUS={BOOKING_STATUS}
                                          selectedId={item._id}
                                          currentData={item}
                                          onEdit={onEdit}
                                          onCopy={onCopy}
                                          anyUpdate={anyUpdate}
                                          onShareAndInvite={onShareAndInvite}
                                          onDelete={onDelete}
                                          checkboxmenu={dropDownMenu}
                                          changeStatusDashboard={
                                            changeStatusDashboard
                                          }
                                          currentStatus={item.status}
                                          onBooking={onBooking}
                                        />
                                      )}
                                    </div>
                                  </td>
                                )
                              )}
                              {ratedByDriver && (
                                <td>
                                  <li>
                                    <a
                                      className="link_color"
                                      onClick={() => {
                                        setModalVisible({
                                          id: item._id,
                                          starRating: item.starRating,
                                          commentToAgency:
                                            item.commentForAgency,
                                          commentForEcr:
                                            item.commentForECRByDriver,
                                          clickedOn: "view",
                                        });
                                        setCommentData({
                                          id: item._id,
                                          starRating: item.starRating,
                                          commentToAgency:
                                            item.commentForAgency,
                                          commentForEcr:
                                            item.commentForECRByDriver,
                                          clickedOn: "view",
                                        });
                                        setCommentModal(true);
                                      }}
                                    >
                                      View
                                    </a>
                                  </li>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        );
                    })
                }
                {
                    !(tableData && tableData.length) && <tbody><tr><td colSpan={tableHeader.length}
                        style={{ fontSize: "1.25rem" }}
                    >No Data Found</td></tr></tbody>
                }
            </table>
        </div >
    )
}