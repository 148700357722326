import React, { useState,useEffect } from 'react';
import moment from 'moment'
import Collapsible from 'react-collapsible';
import './styles.scss';
const { defaultConfig: { PLATFORM } } = require(`../../../../config/default`);
const { FREQUENCY } = require(`../../../../shared/${PLATFORM}/constants/`);
const {
  CustomTooltip,
} = require(`../custom-tooltip`);
export const AccordionComponent = ({ vehicle = {}, faq }) => {

    const [trigger, setTrigger] = useState({
        importantDetails: false,
        insurance: false,
        location: false,
        vehicleDetails: false,
        policy: false,
        faq: false,
        cancellationPolicy: false,
        extraItems: false
    })
  
    const strip = (html) => {
        html = (html).replace(/&nbsp;/gi, " ")
        var doc = new DOMParser().parseFromString(html, 'text/html');
        let terms = document.getElementById('terms');
        terms.innerHTML = doc.body.textContent
    }
    const handletrigger = (collapse, triggerValue) => {
        let c = document.getElementsByClassName('Collapsible__trigger is-open')
        let IsOpen = trigger[`${triggerValue}`]
        let arr = [...c]
        if (arr.length > 0 && IsOpen === true) {
            arr.map(obj => {
                if (obj.innerHTML === collapse) {
                    return setTrigger({ ...trigger, [`${triggerValue}`]: false })
                }
            })
        }

        if (IsOpen === false) {
            Object.keys(trigger).forEach(v => { if (trigger[v] !== trigger[`${triggerValue}`]) { trigger[v] = false } })
            setTrigger({ ...trigger, [`${triggerValue}`]: true })
        }
    }
  const [hovered, checkHover] = useState({ first: false });
  var terms = vehicle && vehicle.termsData && vehicle.termsData.description;
  useEffect(() => {
      var terms = vehicle && vehicle.termsData && vehicle.termsData.description;
       terms = terms.replace(/<br>/g,'');
        let doc = document.getElementById('termsData');
        doc.innerHTML = (terms) || '<span>Not Available</span>'
        return (() => {
        })
    }, [])
  var dataTerms = terms.replace(/<p>+/g, "").slice(0,-4);
 
  const { driverLicenseRequirement } = vehicle && vehicle.agencyData
    return (
      <div className="accordion_sec">
        <Collapsible
          trigger="Important Details"
          open={trigger.importantDetails}
          handleTriggerClick={() =>
            handletrigger("Important Details", "importantDetails")
          }
        >
          <div className="row">
            <div className="col-md-6">
              {driverLicenseRequirement ? (
                <>
                  <h6>
                    <strong>Driver Licence Details</strong>
                  </h6>
                </>
              ) : (
                "Not Available"
              )}
              <div className="row veh_detail">
                <div className="col text-left">Licence Type</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.licenseTypeData &&
                    vehicle.licenseTypeData.name}
                </div>
              </div>
              {vehicle &&
                vehicle.vehicleData &&
                vehicle.vehicleData.isLicenseRequired && (
                  <p>
                    Driver required to hold a Driver Licence for a minimum of 1
                    year
                  </p>
                )}
              {driverLicenseRequirement &&
                driverLicenseRequirement.driverWithRestrictedLicense && (
                  <p>
                    Drivers with NZ Restricted Licences are allowed to request a
                    booking for this vehicle
                  </p>
                )}
              {driverLicenseRequirement &&
                driverLicenseRequirement.overseasDriversAllowed && (
                  <p>
                    Drivers with International Driver Licences are allowed to
                    request a booking for this vehicle. Overseas drivers are
                    permitted and a valid drivers licence in English is
                    required. For a Non English licence; an official translation
                    will be needed to be presented with the original licence.
                  </p>
                )}
            </div>
            <div className="col-md-6">
              {vehicle &&
              vehicle.fuelOfferData &&
              vehicle.fuelOfferData.value ? (
                <>
                  <h6>
                    <strong> Fuel Offer</strong>
                  </h6>
                  <ul className="list-group">
                    <li className="list-group-item">{`Free ${
                      vehicle &&
                      vehicle.fuelOfferData &&
                      vehicle.fuelOfferData.value
                    } of Fuel.`}</li>
                  </ul>
                </>
              ) : (
                ""
              )}

              {vehicle &&
              vehicle.ferryCostData &&
              vehicle.ferryCostData.name ? (
                <>
                  <h6>
                    <strong>Ferry Cost</strong>
                  </h6>
                  <ul className="list-group">
                    <li className="list-group-item">{`${
                      vehicle &&
                      vehicle.ferryCostData &&
                      vehicle.ferryCostData.name
                    }`}</li>
                  </ul>
                </>
              ) : (
                ""
              )}
              {vehicle && vehicle.expenses ? (
                <>
                  <h6>
                    <strong>Expenses Offer</strong>
                  </h6>
                  <ul className="list-group">
                    <li className="list-group-item">{`${
                      vehicle && vehicle.expenses && vehicle.expenses
                    }`}</li>
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Collapsible>
        {trigger.extraItems && (
          <CustomTooltip
            tooltipText={
              "Extra items to be requested within the rental vehicle agency after booking confirmation"
            }
            hoveredKey="first"
            checkHover={checkHover}
            hovered={hovered}
            imgContainerClass={"tooltip-accro"}
            style={{ left: "150px", top: "0px" }}
          ></CustomTooltip>
        )}
        <Collapsible
          trigger="Extra Items"
          classList="tooltip-accro"
          open={trigger.extraItems}
          handleTriggerClick={() => handletrigger("Extra Items", "extraItems")}
        >
          <div className="col-md-6">
            {vehicle &&
            vehicle.extraItemsData &&
            vehicle.extraItemsData.items.length > 0 ? (
              <>
                <ul className="list-group">
                  {vehicle.extraItemsData.items.map((item, index) => {
                    return (
                      <li className="list-group-item" key={index}>
                        {" "}
                        {`${item.name} at $${item.price} ${
                          FREQUENCY[item.frequency - 1].label
                        }`}
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              "Not Available"
            )}
          </div>
        </Collapsible>
        <Collapsible
          trigger="Insurance & Bond"
          open={trigger.insurance}
          handleTriggerClick={
            (() => handletrigger("Insurance & Bond", "insurance"),
            trigger.insurance)
          }
        >
          {vehicle && vehicle.insuranceData ? (
            <>
              <div className="row veh_detail">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col text-left">Insurance Name</div>
                    <div className="col text-right">
                      {vehicle &&
                        vehicle.insuranceData &&
                        vehicle.insuranceData.name}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col text-left">Excess</div>
                    <div className="col text-right">
                      {`$${
                        vehicle &&
                        vehicle.insuranceData &&
                        vehicle.insuranceData.excess
                      }`}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col text-left">Bond:</div>
                    <div className="col text-right">
                      {`$${
                        vehicle &&
                        vehicle.insuranceData &&
                        vehicle.insuranceData.bond
                      }`}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col text-left">Daily Fee:</div>
                    <div className="col text-right">
                      {`$${
                        vehicle &&
                        vehicle.insuranceData &&
                        vehicle.insuranceData.dailyFee
                      }`}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "Insurance Not included in this relocation."
          )}
        </Collapsible>
        <Collapsible
          trigger="Location"
          open={trigger.location}
          handleTriggerClick={() => handletrigger("Location", "location")}
        >
          <div className="location_detail">
            <div className="location_vac text-uppercase row">
              <h5>Pickup & Dropoff Branch Details</h5>
            </div>
            <div className="row veh_detail">
              <div className="col-md-6">
                <div className="row">
                  <div className="col text-left">Pickup</div>
                  <div className="col text-right">
                    {vehicle &&
                      vehicle.pickupBranchData &&
                      vehicle.pickupBranchData.name}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">City</div>
                  <div className="col text-right">
                    {vehicle &&
                      vehicle.pickupBranchData &&
                      vehicle.pickupBranchData.city}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">Opens At</div>
                  <div className="col text-right">
                    {moment(
                      vehicle &&
                        vehicle.pickupBranchData &&
                        vehicle.pickupBranchData.openFrom
                    ).format("LT")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">Closes At</div>
                  <div className="col text-right">
                    {moment(
                      vehicle &&
                        vehicle.pickupBranchData &&
                        vehicle.pickupBranchData.closeBy
                    ).format("LT")}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col text-left">Dropoff</div>
                  <div className="col text-right">
                    {vehicle &&
                      vehicle.dropoffBranchData &&
                      vehicle.dropoffBranchData.name}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">City</div>
                  <div className="col text-right">
                    {vehicle &&
                      vehicle.dropoffBranchData &&
                      vehicle.dropoffBranchData.city}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">Opens At</div>
                  <div className="col text-right">
                    {moment(
                      vehicle &&
                        vehicle.dropoffBranchData &&
                        vehicle.dropoffBranchData.openFrom
                    ).format("LT")}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">Closes At</div>
                  <div className="col text-right">
                    {moment(
                      vehicle &&
                        vehicle.dropoffBranchData &&
                        vehicle.dropoffBranchData.closeBy
                    ).format("LT")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapsible>

        <Collapsible
          trigger="Vehicle details"
          open={trigger.vehicleDetails}
          handleTriggerClick={() =>
            handletrigger("Vehicle details", "vehicleDetails")
          }
        >
          <div className="row veh_detail">
            <div className="col-md-6">
              <div className="row">
                <div className="col text-left">Fuel Type</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.fuelTypeData &&
                    vehicle.fuelTypeData.fuelType}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Transmission </div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.transmissionData &&
                    vehicle.transmissionData.name}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Adult Seats</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.adultSeats}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Child Seats</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.childSeats}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Vehicle Age Range</div>
                <div className="col text-right">
                  {`${
                    vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.yearRange &&
                    vehicle.vehicleData.yearRange.from
                  } - ${
                    vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.yearRange &&
                    vehicle.vehicleData.yearRange.to
                  }`}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col text-left">Small Luggage Space</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.smallLuggageSpace}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Large Luggage Space</div>
                <div className="col text-right">
                  {vehicle &&
                    vehicle.vehicleData &&
                    vehicle.vehicleData.largeLuggageSpace}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Vehicle Code </div>
                <div className="col text-right">
                  {vehicle &&
                  vehicle.vehicleData &&
                  vehicle.vehicleData.vehicleCode
                    ? vehicle &&
                      vehicle.vehicleData &&
                      vehicle.vehicleData.vehicleCode
                    : "Nil"}
                </div>
              </div>
              <div className="row">
                <div className="col text-left">Air Con</div>
                <div className="col text-right">
                  {vehicle &&
                  vehicle.vehicleData &&
                  vehicle.vehicleData.airConditionType === true
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            </div>
          </div>
        </Collapsible>
        <Collapsible
          trigger="Cancellation Policy"
          open={trigger.cancellationPolicy}
          handleTriggerClick={() =>
            handletrigger("Cancellation Policy", "cancellationPolicy")
          }
        >
          <p>
            After a relocation request has been made by the Driver and confirmed
            by the rental vehicle agency, a cancellation fee will apply if the
            Driver decides to cancel the booking or fail to collect the vehicle
            (no show). When the driver cancels the booking prior to 24hrs from
            the pickup date and time, a fee of NZD30 will apply. For bookings
            cancelled on the day of pickup (24hrs) or if the driver fails to
            collect the vehicle (no show), a fee of NZD150 will apply. These
            charges will be applied to the bank card the Driver has provided to
            us.
          </p>
          <p>
            Cancellations must be made on the Driver’s portal via the site. The
            driver also must notify the vehicle rental agency via email or
            phone.
          </p>
        </Collapsible>
        <Collapsible
          trigger={`Policy, Terms & Conditions`}
          open={trigger.policy}
          handleTriggerClick={
            (() => handletrigger("Policy, Terms & Conditions", "policy"),
            trigger.policy)
          }
        >
          <div id="terms">
            <p>{vehicle && vehicle.termsData && vehicle.termsData.name}</p>
            <p id="termsData">
          
            </p>
          </div>
        </Collapsible>

        <Collapsible
          trigger="FAQ's"
          open={trigger.faq}
          handleTriggerClick={() => handletrigger("FAQ's", "faq")}
        >
          {faq && faq.length > 0 ? (
            <>
              <h4 className="mb-3">Frequently Asked Questions</h4>
              <hr />
              {faq.map((faq, index) => {
                return (
                  <div className="faq_list" key={index}>
                    <h6>{faq.question}</h6>
                    <p>{faq.answer}</p>
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </Collapsible>
      </div>
    );

}