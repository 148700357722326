import React, { useState, useEffect } from "react";
import './styles.scss';
import {
    injectStripe
} from 'react-stripe-elements';
import { useHistory } from "react-router-dom";
import moment from "moment";
const { defaultConfig: { PLATFORM, LOCATION ,VERSION} } = require(`../../../../../../config/default`);
const { BOOKING_STATUS } = require(`../../../../../../shared/${PLATFORM}/constants`)
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const {
  PendingPopup,
} = require(`../../../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/pending-popup/index`);
const {
  TermsPopup,
} = require(`../../../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/terms-popup/index`);
const {
  CancellationPopup,
} = require(`../../../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/cancellation-popup/index`);
const { ROUTES, PAY_ICON, REMOVE_ICON, VISA_ICON,
    MAESTRO_ICON,
    MASTER_CARD_ICON,
    JCB_ICON,
    HYPER_CARD_ICON,
    ELO_ICON,
    DISCOVER_ICON,
    DINERS_ICON,
    AMEX_ICON,
    ALIPAY_ICON,
    PAYPAL_ICON,
    UNION_ICON,
    VERVE_ICON } = require(`../../../../../../shared/ecr/constants`)

export const Form = (props) => {
    let history = useHistory();
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ""
    })
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [completeProfileReminder, setCompleteProfileRemainder] = useState(false)
    const [selectedCard, setSelectedCard] = useState(undefined)
    const [confirmBox, setConfirmBox] = useState(false)
    const [readTerms,setReadTerms]=useState(false)
    const [deleteItemData, setDeleteItemData] = useState('')
  const [cancellationPopupVisible, setCancellationPopupVisible] = useState(false)
  const [pendingPopupVisible, setPendingPopupVisible] = useState(false)
  const [termsPopupVisible,setTermsPopupVisible]=useState(false)
  const [card, setCard] = useState({
    cardNumber: '',
    cvc: '',
    expMonth: '',
    expYear: '',
    name:''
  })
 const loadStripe = () => {
     if (!window.document.getElementById("stripe-script")) {
       var s = window.document.createElement("script");
       s.id = "stripe-script";
       s.type = "text/javascript";
       s.src = "https://js.stripe.com/v2/";
       s.onload = () => {
         window["Stripe"].setPublishableKey(
           "pk_live_vT72SYq1YafKURkW1BJE14LS00jziTgrH0"
         );
       };
       window.document.body.appendChild(s);
     }
   };
    useEffect(() => {
        props.getCards()
        props.getDriverProfile(() => { }, () => { })
      loadStripe()
    }, [])

    useEffect(() => {
        let tempSelectedCard;
        props.cards.filter((item) => {
            if (item.default) {
                tempSelectedCard = item.id
            }
        })
      setSelectedCard(tempSelectedCard)
    }, [props.cards])
    const handleCardChange = (e) => {
  setCard({
    ...card,
    [e.target.name]: e.target.validity.valid ? e.target.value : card[e.target.name],
  });
};
  const handleSubmit = (ev) => {
    if (!readTerms)
    {
      setSnackBarData({
        variant: "error",
        message: "Please check Terms and conditions to proceed",
      });
      return setOpenSnackBar(true);
    }
    ev.preventDefault();
    const { startLoader, stopLoader } = props
    startLoader()
    !readTerms && stopLoader()
    readTerms && window.Stripe.card.createToken({
      number: card.cardNumber,
      exp_month: card.expMonth,
      exp_year: card.expYear,
      cvc: card.cvc,
      name: card.name
    }, (status, payload) => {
        if (!readTerms)
        {
           setSnackBarData({
             variant: "error",
             message: "Please Accept Terms and conditions to proceed",
           });
          setOpenSnackBar(true);
          }
      if (!!(payload && payload.error && !readTerms)) {
        setSnackBarData({
                            variant: 'error',
                            message: (payload && payload.error && payload.error.message)
                        })
                        setOpenSnackBar(true)
                        stopLoader()
                    }  else {
        stopLoader()
        if (!props.getDriverProfileData.name || props.getDriverProfileData.name === "" ||
            !props.getDriverProfileData.surname ||
            props.getDriverProfileData.surname === "" || !props.getDriverProfileData.email ||
            props.getDriverProfileData.email === "" || !props.getDriverProfileData.phoneNumber ||
            props.getDriverProfileData.phoneNumber.code === "" || !props.getDriverProfileData.city ||
            props.getDriverProfileData.city === "" || !props.getDriverProfileData.country ||
            props.getDriverProfileData.country === "" || !props.getDriverProfileData.dob ||
            props.getDriverProfileData.dob === "" || !props.getDriverProfileData.phoneNumber ||
            props.getDriverProfileData.phoneNumber.phone === "") {
           return setCompleteProfileRemainder(true)
        } 
        if (props.userToken !== null && (!props.getDriverProfileData.loginType || props.getDriverProfileData.loginType === 2 ||
          props.getDriverProfileData.loginType === 3 || props.getDriverProfileData.loginType === 1)) {
          // if (!props.getDriverProfileData.name || props.getDriverProfileData.name === "" ||
          //   !props.getDriverProfileData.surname ||
          //   props.getDriverProfileData.surname === "" || !props.getDriverProfileData.email ||
          //   props.getDriverProfileData.email === "" || !props.getDriverProfileData.phoneNumber ||
          //   props.getDriverProfileData.phoneNumber.code === "" || !props.getDriverProfileData.city ||
          //   props.getDriverProfileData.city === "" || !props.getDriverProfileData.country ||
          //   props.getDriverProfileData.country === "" || !props.getDriverProfileData.dob ||
          //   props.getDriverProfileData.dob === "" || !props.getDriverProfileData.phoneNumber ||
          //   props.getDriverProfileData.phoneNumber.phone === "") {
          //   setCompleteProfileRemainder(true)
          // } else {
            startLoader()
            props.addCard({
              cardId: (payload && payload.card && payload.card.id),
              stripeToken: (payload && payload.id),
            }, (response) => {
                                  
              setSnackBarData({
                variant: 'success',
                message: response.msg
              })
              setOpenSnackBar(true)
              setSelectedCard(payload.card.id)
              props.setDefaultCard(payload.card.id, (response) => {
                setSnackBarData({
                  variant: response.status ? 'success' : 'error',
                  message: response.msg || 'error'
                })
                setOpenSnackBar(true)
                if (props.previousLocation == ROUTES.VEHICLE_SUMMARY) {
                  makeLisingRequest()
                } else {
                  stopLoader()
                  props.getCards()
                }
              }, (response) => {
                setSnackBarData({
                  variant: response.status ? 'success' : 'error',
                  message: response.msg || 'error'
                })
                setOpenSnackBar(true)
                        
                stopLoader()
              });

                  const cardData1 = {
                    ...card,
                    cardNumber: "",
                    expMonth: "",
                    expYear: "",
                    cvc: "",
                    name: "",
                  };
                  setCard(cardData1);                
            },
              (response) => {
                setSnackBarData({
                  variant: 'error',
                  message: response.msg
                })
                setOpenSnackBar(true)
                stopLoader()
                                        
              }
            )
          // }

        } else {
          startLoader()
          props.addCard({
            cardId: (payload && payload.card && payload.card.id ),
            stripeToken: (payload && payload.id),
          }, (response) => {
                               
            setSnackBarData({
              variant: 'success',
              message: response.msg
            })
            setOpenSnackBar(true)
    
            stopLoader()
                                                           
            if (props.previousLocation == ROUTES.VEHICLE_SUMMARY) {
              makeLisingRequest()
            } else {
              props.getCards()
            }
              const cardData = { ...card, cardNumber: '', expMonth: '', expYear: '', cvc: '', name: '' }
              setCard(cardData)
          },
            (response) => {
              setSnackBarData({
                variant: 'error',
                message: response.msg
              })
              setOpenSnackBar(true)
              stopLoader()
              const cardData2 = {
                ...card,
                cardNumber: "",
                expMonth: "",
                expYear: "",
                cvc: "",
                name: "",
              };
              setCard(cardData2);
            }
          )
        }    
      }
    });
  };                      

  const makeLisingRequest = () => {
    let tempPickupdate = moment(new Date(props.setVehicleDate && props.setVehicleDate.pickupdate)).format().split("T")[0];
    let tempDropoffdate = moment(new Date(props.setVehicleDate && props.setVehicleDate.returnDate)).format().split("T")[0];
        let postData = {
          startDate: tempPickupdate,
          endDate: tempDropoffdate,
          selectedFreeDays: props.paidDays && props.paidDays.free ? props.paidDays.free : 0,
          selectedPaidDays: props.paidDays && props.paidDays.paid ? props.paidDays.paid : 0,
          bookingPrice: (props.vehicle && props.vehicle.ratePerDay ? props.vehicle && props.vehicle.ratePerDay : 0) * props.paidDays.paid,
          status: BOOKING_STATUS.PENDING
        }
        props.makeRequest({ id: props.vehicle._id, data: postData }, (response) => {
            history.replace(ROUTES.SUCCESSFUL_BOOKING)
        }, (error) => {
            setSnackBarData({
                variant: error.status ? 'success' : 'error',
                message: error.msg || 'error'
            });
            setOpenSnackBar(true)
        })
    }

    // const handleBlur = () => {
    // };
    // const handleChange = (event) => {
    // }

    // const handleFocus = () => {
    // };
    // const handleReady = () => {
    // };
    const cardDetector = (card) => {
        switch (card.toLowerCase()) {
            case 'visa':
                return VISA_ICON
            case 'mastercard':
                return MASTER_CARD_ICON
            case 'maestro':
                return MAESTRO_ICON
            case 'jcb':
                return JCB_ICON
            case 'hypercard':
                return HYPER_CARD_ICON
            case 'elo':
                return ELO_ICON
            case 'discover':
                return DISCOVER_ICON
            case 'diners':
                return DINERS_ICON
            case 'amex':
                return AMEX_ICON
            case 'alipay':
                return ALIPAY_ICON
            case 'paypal':
                return PAYPAL_ICON
            case 'unionpay':
                return UNION_ICON
            case 'verve':
                return VERVE_ICON
            default:
                return PAY_ICON
        }
    };
    return (
      <>
        <SnackbarWrapper
          visible={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          variant={snackbarData.variant}
          message={snackbarData.message}
        />
        <CancellationPopup
          dialogContent={STRINGS.CANCELLATION_POPUP_CONTENT}
          dialogTitle={"Cancellation Policy"}
          modalType={"cancellationPopup"}
          modalVisibility={cancellationPopupVisible}
          toggleDialogModal={() =>
            setCancellationPopupVisible(!cancellationPopupVisible)
          }
          onRejection={() => {
            setCancellationPopupVisible(false);
          }}
        />
        <PendingPopup
          dialogContent={STRINGS.PENDING_POPUP_CONTENT}
          dialogTitle={"Pending Request Policy"}
          modalType={"pendingPopup"}
          modalVisibility={pendingPopupVisible}
          toggleDialogModal={() => setPendingPopupVisible(!pendingPopupVisible)}
          onRejection={() => {
            setPendingPopupVisible(false);
          }}
        />
        <TermsPopup
          dialogContent={STRINGS.TERMS_POPUP_CONTENT}
          dialogTitle={"Terms and Conditions"}
          modalType={"termsPopup"}
          cancelButtonTitle={"CANCEL"}
          agreeButtonTitle={"I AGREE"}
          onCancel={() => {
            setReadTerms(false);
              setTermsPopupVisible(false)
          }}
          onAgree={() => {
            setReadTerms(true);
            setTermsPopupVisible(false);
          }}
          modalVisibility={termsPopupVisible}
          toggleDialogModal={() => setTermsPopupVisible(!termsPopupVisible)}
          onRejection={() => {
            setTermsPopupVisible(false);
          }}
        />
        <DecisionPopup
          modalVisibility={completeProfileReminder}
          dialogTitle={STRINGS.COMPLETE_PROFILE_TITLE}
          dialogContent={STRINGS.COMPLETE_PROFILE_CONTENT}
          confirmButtonTitle={STRINGS.CONFIRM}
          rejectButtonTitle={STRINGS.CANCEL}
          toggleDialogModal={() =>
            setCompleteProfileRemainder(!completeProfileReminder)
          }
          onConfirmation={() => {
            history.replace(ROUTES.DRIVER_PROFILE);
          }}
          onRejection={() => setCompleteProfileRemainder(false)}
        />
        {confirmBox && (
          <DecisionPopup
            modalVisibility={confirmBox}
            dialogTitle={"Delete Card"}
            toggleDialogModal={() => setConfirmBox(false)}
            dialogContent={"Are you sure, you want to delete this card?"}
            confirmButtonTitle={"confirm"}
            rejectButtonTitle={"cancel"}
            onConfirmation={() => {
              props.deleteCard(
                deleteItemData,
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg || "error",
                  });
                  setOpenSnackBar(true);
                  setConfirmBox(false);
                  props.getCards();
                },
                (response) => {
                  setSnackBarData({
                    variant: response.status ? "success" : "error",
                    message: response.msg || "error",
                  });
                  setOpenSnackBar(true);
                }
              );
            }}
            onRejection={() => {
              setConfirmBox(false);
            }}
          />
        )}
        <div
          className={
            props.previousLocation != ROUTES.VEHICLE_SUMMARY
              ? "app-main_outer driver_head"
              : "driver_head"
          }
        >
          <div
            class={
              props.previousLocation != ROUTES.VEHICLE_SUMMARY
                ? "container-fluid"
                : "container"
            }
          >
            <h2 class="mb-4"> Payment Details </h2>
            <form onSubmit={handleSubmit} className="stripe-payment">
              <h4 class="mb-3">Add Card</h4>
              <div class="row">
                <div class="col-md-7">
                  <div class="card">
                    <div class="card-block">
                      <div class="deatil_payment col-md-12 px-0 text-center">
                        <p>
                          <strong>
                            To confirm your booking, you are required to enter
                            your credit or debit card here. You won't be charged
                            to use EasyCaRelo website and to request vehicle
                            relocations. However, when your booking request is
                            accepted by the rental vahicle agency, cancellation
                            fees may apply.{" "}
                            <a
                              onClick={() => setCancellationPopupVisible(true)}
                              href="#"
                            >
                              {" "}
                              Please click here for details.{" "}
                            </a>
                          </strong>
                        </p>
                        <p>
                          With credit card authorization, you may see a $1
                          pending charge on your bank statement as part of the
                          card authorization process. This is a temporary
                          authorization charge and it will disappear from your
                          statement.{" "}
                          <a
                            onClick={() => setPendingPopupVisible(true)}
                            href="#"
                          >
                            {" "}
                            Please click here for details.{" "}
                          </a>
                        </p>
                        <div class="custom-control custom-checkbox mb-3 text-center">
                          <input
                            checked={readTerms}
                            onChange={() => setReadTerms(!readTerms)}
                            type="checkbox"
                            class="custom-control-input"
                            id="same-address"
                          />
                          <label
                            class="custom-control-label"
                            for="same-address"
                          >
                            I have read and agree with EasyCarRelo{" "}
                            <a
                              href="#"
                              onClick={() => setTermsPopupVisible(true)}
                            >
                              Terms and Conditions
                            </a>
                          </label>
                        </div>
                      </div>

                      <form role="form" class="w-100">
                        <h4 class="text-white justify-content-center btn-primary px-2 mb-3">
                          Credit Card Authorization
                        </h4>
                        <div class="form-row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                class="form-control"
                                name="name"
                                pattern="[a-zA-Z]+((_|-| )*[a-zA-Z]*)+"
                                value={card.name}
                                onChange={handleCardChange}
                                placeholder="Card Holder Name"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Credit Card Number</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  pattern="[0-9]*"
                                  className="form-control"
                                  value={card.cardNumber}
                                  placeholder="Valid Card Number"
                                  name="cardNumber"
                                  maxLength="16"
                                  onChange={handleCardChange}
                                />
                                <span class="input-group-addon">
                                  <i class="fa fa-credit-card"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-8">
                            <div class="form-group">
                              <label>
                                <span class="hidden-xs">EXPIRATION</span> DATE
                              </label>
                              <div class="d-flex">
                                <select
                                  style={{ marginRight: "10px" }}
                                  name="expMonth"
                                  className="form-control"
                                  onChange={handleCardChange}
                                  value={card.expMonth}
                                >
                                  <option value="">Select Month</option>
                                  <option value="1">january(01)</option>
                                  <option value="2">february(02)</option>
                                  <option value="3">march(03)</option>
                                  <option value="4">april(04)</option>
                                  <option value="5">may(05)</option>
                                  <option value="6">june(06)</option>
                                  <option value="7">july(07)</option>
                                  <option value="8">august(08)</option>
                                  <option value="9">september(09)</option>
                                  <option value="10">october(10)</option>
                                  <option value="11">november(11)</option>
                                  <option value="12">december(12)</option>
                                </select>
                                <select
                                  name="expYear"
                                  className="form-control"
                                  onChange={handleCardChange}
                                  value={card.expYear}
                                >
                                  <option value="">Select Year</option>
                                  {[1, 2, 3, 4, 5, 6].map((item, index) => {
                                    return (
                                      <option
                                        value={`${
                                          new Date().getFullYear() + index
                                        }`}
                                      >
                                        {new Date().getFullYear() + index}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 float-xs-right">
                            <div class="form-group">
                              <label>CVC</label>
                              <input
                                type="text"
                                name="cvc"
                                pattern="[0-9]*"
                                value={card.cvc}
                                className="form-control"
                                placeholder="CVC"
                                maxLength="4"
                                onChange={handleCardChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div class="card-footer border-0">
                      <div class="form-row">
                        <div class="col-md-7 paymentWrap">
                          <div
                            class="btn-group paymentBtnGroup btn-group-justified"
                            data-toggle="buttons"
                          >
                            <label class="btn paymentMethod">
                              <div class="method visa active"></div>
                              <input type="radio" name="options" checked />
                            </label>
                            <label class="btn paymentMethod">
                              <div class="method master-card"></div>
                              <input type="radio" name="options" />
                            </label>
                            <label class="btn paymentMethod">
                              <div class="method amex"></div>
                              <input type="radio" name="options" />
                            </label>
                            <label class="btn paymentMethod">
                              <div class="method vishwa"></div>
                              <input type="radio" name="options" />
                            </label>
                            <label class="btn paymentMethod">
                              <div class="method powered"></div>
                              <input type="radio" name="options" />
                            </label>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <button class="btn btn-success btn-lg btn-block rounded-0">
                            PROCEED
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {!!props.cards.length && (
              <div class="row">
                <div className="col-md-7 mt-4">
                  <div className="saved_cards rounded">
                    <div className="savedcard_details text-capitalize">
                      <h5>Saved card details</h5>
                    </div>
                    <ul>
                      {props.cards.map((item, index) => {
                        return (
                          <li className="radio" key={index + ""}>
                            <input
                              name="radio"
                              type="radio"
                              checked={item.id == selectedCard}
                            />
                            <label
                              htmlFor="radio-1"
                              className="radio-label"
                              onClick={() => {
                                setSelectedCard(item.id);
                              }}
                            ></label>
                            <div className="col-md-2 col-sm-2 col-2 card_type pr-0">
                              <img
                                src={cardDetector(item.brand)}
                                alt="ECR"
                                className="img-fluid"
                                width="30px"
                              />
                            </div>
                            <div className="col-md-9 col-sm-9 col-9 media-body">
                              <h6 className="card-detail-text">
                                Ending with {item.last4} <br />
                                (Expires on{" "}
                                {item.exp_month.toString().length > 1
                                  ? item.exp_month
                                  : `0${item.exp_month}`}
                                ,{item.exp_year})
                              </h6>
                            </div>

                            <div
                              className="col-md-1 col-sm-1 col-1 remove_card_details p-0"
                              onClick={() => {
                                setDeleteItemData(item.id);
                                setConfirmBox(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={REMOVE_ICON}
                                alt="ECR"
                                className="img-fluid"
                                width="20px"
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="continue_continue text-left">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        onClick={() => {
                          const {
                            stopLoader,
                            startLoader,
                            setDefaultCard,
                          } = props;

                          if (selectedCard) {
                            if (
                              props.userToken !== null &&
                              (!props.getDriverProfileData.loginType ||
                                props.getDriverProfileData.loginType === 2 ||
                                props.getDriverProfileData.loginType === 3)
                            ) {
                              if (
                                !props.getDriverProfileData.name ||
                                props.getDriverProfileData.name === "" ||
                                !props.getDriverProfileData.surname ||
                                props.getDriverProfileData.surname === "" ||
                                !props.getDriverProfileData.email ||
                                props.getDriverProfileData.email === "" ||
                                !props.getDriverProfileData.phoneNumber ||
                                props.getDriverProfileData.phoneNumber.code ===
                                  "" ||
                                !props.getDriverProfileData.city ||
                                props.getDriverProfileData.city === "" ||
                                !props.getDriverProfileData.country ||
                                props.getDriverProfileData.country === "" ||
                                !props.getDriverProfileData.dob ||
                                props.getDriverProfileData.dob === "" ||
                                !props.getDriverProfileData.phoneNumber ||
                                props.getDriverProfileData.phoneNumber.phone ===
                                  ""
                              ) {
                                setCompleteProfileRemainder(true);
                              } else {
                                startLoader();
                                setDefaultCard(
                                  selectedCard,
                                  (response) => {
                                    setSnackBarData({
                                      variant: response.status
                                        ? "success"
                                        : "error",
                                      message: response.msg || "error",
                                    });
                                    setOpenSnackBar(true);
                                    if (
                                      props.previousLocation ==
                                      ROUTES.VEHICLE_SUMMARY
                                    ) {
                                      makeLisingRequest();
                                    } else {
                                      stopLoader();
                                    }
                                  },
                                  (response) => {
                                    setSnackBarData({
                                      variant: response.status
                                        ? "success"
                                        : "error",
                                      message: response.msg || "error",
                                    });
                                    setOpenSnackBar(true);
                                    stopLoader();
                                  }
                                );
                              }
                            } else {
                              startLoader();
                              setDefaultCard(
                                selectedCard,
                                (response) => {
                                  setSnackBarData({
                                    variant: response.status
                                      ? "success"
                                      : "error",
                                    message: response.msg || "error",
                                  });
                                  setOpenSnackBar(true);
                                  if (
                                    props.previousLocation ==
                                    ROUTES.VEHICLE_SUMMARY
                                  ) {
                                    makeLisingRequest();
                                  } else {
                                    stopLoader();
                                  }
                                },
                                (response) => {
                                  setSnackBarData({
                                    variant: response.status
                                      ? "success"
                                      : "error",
                                    message: response.msg || "error",
                                  });
                                  setOpenSnackBar(true);

                                  stopLoader();
                                }
                              );
                            }
                          } else {
                            setSnackBarData({
                              variant: "error",
                              message: "Please select the card.",
                            });
                            setOpenSnackBar(true);
                          }
                        }}
                        disabled={!selectedCard}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
}

export const Screen = injectStripe(Form)