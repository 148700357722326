import { REHYDRATE } from "redux-persist";
import {
    SAVE_EDIT_DATA,
    SET_VEHICLES
} from '../actions';


const initialCommonState = {
    dashboardData: '',
    dashboardTableData: "",
    anyUpdate: false,
    editVehicleData: {},
    vehicleImagesforModal: []
};

const VehicleReducer = (state = { ...initialCommonState }, action) => {
    switch (action.type) {
        case SET_VEHICLES:
            return {
                ...state, vehicleImagesforModal: action.data
            }
        case SAVE_EDIT_DATA:
            return {
                ...state, editVehicleData: action.data
            }
        case REHYDRATE:
            let common = ((action || {}).payload || {}).VehicleReducer || initialCommonState
            return {
                ...state,
                editVehicleData: common.editVehicleData,
                vehicleImagesforModal: common.vehicleImagesforModal
            };
        default:
            return state;
    }
};

export default VehicleReducer;