import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from 'react-redux';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../../config/default`);
const { Form } = require(`../../../../../../../components/${PLATFORM}/atoms/form`);
const { STRINGS } = require(`../../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { TextArea } = require(`../../../../../../../components/${PLATFORM}/atoms/text-area`);
const { StarRatingComponent } = require(`../../../../../../../components/${PLATFORM}/atoms/star-component`);
const { onSubmitFail } = require(`../../../../../../../helpers/${PLATFORM}`);

const ViewCommentForm = ({
  content
}) => {
    return (
        <>
        <StarRatingComponent initialRating={content.rateForDriver || content.starRating} readonly={true}/>
        <Form >
           <Field
                name={STRINGS.COMMENT_AGENCY_NAME}
                component={TextArea}
                placeholder={"Public Feedback"}
                type={'textarea'}
                config={{readOnly: true, 
                    value: content.commentForDriver ? content.commentForDriver: content.commentToAgency,
                    onChange: (e) => {return false}
                }}
                disabled={true}
            />
          <Field
            name={STRINGS.COMMENT_AGENCY_NAME}
            component={TextArea}
            placeholder={"Private Feedback"}
            type={"textarea"}
            config={{
              readOnly: true,
              value: content.commentForDriver
                ? content.commentForECRByAgency
                : content.commentForEcr,
              onChange: (e) => {
                return false;
              },
            }}
            disabled={true}
          />
        </Form >
        </>
    )
}

const mapStateToProps = (state, props) => {
    return {
        content: props.content
    };
}

const reduxFormFunction = reduxForm({
    form: "ViewCommentForm",
    fields: ['Name', 'Email', 'phoneKey', 'PhoneKey', 'Address', 'Dob'],
    onSubmitFail,
    // validate: validator,
    enableReinitialize: true
})(ViewCommentForm);

export const ViewCommentComponent = connect(mapStateToProps, null)(reduxFormFunction)